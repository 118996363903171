export type Pattern = string | "*" | "**" | RegExp
export type PatternSequence = Pattern[]

export type Trigger = {
    test: (eventName: string) => boolean
    triggered: boolean
}

export type CreateTriggerParams = {
    sequence: PatternSequence
    persistTriggeredState?: boolean
}

export const createTrigger = ({
    sequence,
    persistTriggeredState = false,
}: CreateTriggerParams): Trigger => {
    let lastMatchedPatternIndex: number | null = null
    let wasTriggered = false

    const testPattern = (
        event: string,
        lastPatternIndex?: number | null,
        doNotIncrement?: boolean
    ): boolean => {
        if (!sequence.length) return false

        const lastPattern = sequence[sequence.length - 1]

        let currentPatternIndex =
            typeof lastPatternIndex === "number"
                ? lastPatternIndex
                : lastMatchedPatternIndex !== null
                ? lastMatchedPatternIndex + 1
                : 0

        if (lastPattern !== "**" && currentPatternIndex > sequence.length - 1) {
            currentPatternIndex = 0
        }

        if (lastPattern === "**" && currentPatternIndex > sequence.length - 1) {
            currentPatternIndex = sequence.length - 1
        }

        const currentPattern = sequence[currentPatternIndex]
        const isCurrentPatternLast = currentPatternIndex === sequence.length - 1

        if (currentPattern === "*") {
            lastMatchedPatternIndex = currentPatternIndex
            return isCurrentPatternLast
        }

        if (currentPattern === "**" && isCurrentPatternLast) {
            lastMatchedPatternIndex = currentPatternIndex
            return true
        }

        if (currentPattern === "**") {
            const nextPatternIndex = currentPatternIndex + 1
            return testPattern(event, nextPatternIndex, true)
        }

        const isCurrentPattenMatched =
            currentPattern instanceof RegExp
                ? currentPattern.test(event)
                : currentPattern === event

        if (isCurrentPattenMatched) {
            lastMatchedPatternIndex = currentPatternIndex
        }

        if (isCurrentPattenMatched && isCurrentPatternLast) {
            return true
        }

        if (!isCurrentPattenMatched && !doNotIncrement) {
            lastMatchedPatternIndex = null
        }

        return false
    }

    return {
        test: (eventName: string) => {
            if (persistTriggeredState && wasTriggered) return true

            const triggered = testPattern(eventName)
            if (triggered) wasTriggered = true
            return triggered
        },
        get triggered() {
            if (persistTriggeredState && wasTriggered) return true
            return lastMatchedPatternIndex === sequence.length - 1
        },
    }
}
