// eslint-disable-next-line max-len
import {
    DomCollectorTarget,
    ParserConfig,
} from "@retentioneering/retentioneering-dom-observer"
import { TrackerEvent } from "./Event"
import { Tracker } from "./Tracker"
import { PatternSequence } from "./lib/Trigger"
import { UrlTrigger } from "./lib/UrlTrigger"
import { InstallPluginFunc } from "./Plugin"
import { SwipeTrackerConfig } from "./SwipeTracker"
import { Fraction as FractionType } from "./Fraction"

/**
 * Custom handler that can process event payload collected by the tracker and
 * transform it before it was sent
 */
export interface CustomEventHandler {
    /**
     * @param event original browser event
     * @param target event target element
     * @param item tracker event config
     * @param payload data to be sent collected by the tracker
     * @return altered payload data
     */
    (
        event: Event,
        target: EventTarget,
        item: EventConfig,
        payload: TrackerEvent
    ): TrackerEvent
}

export type Selector = string

export type SendValue = "none" | "hashOfValue" | "plainValue"

export type EventModifier = (event: any) => void

export type PageLoadHook = (tracker: Tracker) => void

// TODO: fix. Re-export type for backward compatibility
export type Fraction = FractionType

/**
 * This object configures endpoint-specific send options
 */
export type EndpointOptions = {
    /**
     * Endpoint name
     */
    name: string
    /**
     * Whether value for input should be submitted as hash, as an actual value
     * or shouldn't be submitted at all. If not specified, then
     * `defaultSendValue` will be used.
     */
    sendValue?: SendValue
}

export type BaseEndpointOptions = {
    sendValue?: SendValue
}

/**
 * Specifies an event type for certain group of elements
 */
export interface EventConfig {
    id?: number

    /**
     * Use to annotate config
     */
    comment?: string
    /**
     * Custom name sent to the API. Use this to aggregate events or identify them in analytics
     */
    eventCustomName?: string
    /**
     * Event name sent to the API
     */
    name?: string
    /**
     * CSS selector for target elements. If `rootSelector` for the parent
     * {@link EventCollection} is not specified,
     * event listeners are attached directly to the elements matched by the
     * selector.
     *
     * Try to keep it simple, usually you can use single class
     *
     * See W3Schools
     * [CSS Selector Reference](https://www.w3schools.com/cssref/css_selectors.asp)
     * and MDN
     * [CSS selectors](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Selectors) articles
     * for the list of available selector patterns and basic rules.
     */
    selector: Selector
    /**
     * Browser event type. See [available events list](https://developer.mozilla.org/en-US/docs/Web/Events)
     * @default 'click'
     */
    eventType?: string
    /**
     * Flag for events from {@link defaultConfig}
     */
    isDefault?: boolean
    /**
     * Whether value for input should be submitted as hash, as an actual value
     * or shouldn't be submitted at all. If not specified, then
     * `defaultSendValue` will be used.
     */
    sendValue?: SendValue
    /**
     * CSS selector for a child in the target element event text will be taken
     * from
     */
    textFrom?: Selector
    /**
     * CSS selector for excluded child elements. Events triggered on
     * excluded elements will not be processed
     */
    exclude?: Selector

    domInfo?: {
        closestSelector?: string
        parseConfig: ParserConfig
    }
    /**
     * Custom handler that can process event payload collected by the tracker
     * and transform it before it was sent
     */
    handler?: CustomEventHandler

    pageFilter?: string | RegExp

    textContentFilter?: string | RegExp
    /**
     * Endpoint-specific send options
     */
    endpointsOptions?: EndpointOptions[]
    fraction?: Fraction
}

/**
 * Collection of events thar have common ancestor
 */
export interface EventCollection {
    id?: number
    /**
     * Needed if elements are in an embedded iframe. Since it's a separate
     * window under the toplevel page it must be handled differently.
     * @example See `bluesleep.ts` for example usage with listening for events
     * from online consultation chat embedded using iframe.
     */
    iframe?: Selector
    /**
     * Use in conjunction with `iframe` param. Set to `true` if iframe is
     * dynamically injected by external plugin rather then
     * declared in page sources. This is often the case with embedded online
     * consultation chat etc.
     * This will enable watching DOM changes to wait till iframe is injected
     * to bind tracker's listeners.
     */
    dynamicallyInjectedIframe?: boolean
    /**
     * CSS selector for common ancestor of the events target elements
     * listeners will be attached to.
     * If not specified event listeners are attached directly to the target
     * elements.
     *
     * Is still applicable if `iframe` is specified.
     *
     * @remark
     * Using `rootSelector` is recommended since most sites add page content
     * dynamically so target DOM elements specified under
     * {@link EventConfig}'s may be not present on the page at the moment of
     * tracker initialization which means that event listeners
     * will not be attached to them. This is also the case with interactive
     * elements like carousels and dropdown menus that are likely
     * to change DOM elements in response to user actions.
     * Binding events to the topmost parent element like `body` solves this
     * problem.
     */
    rootSelector?: Selector
    /**
     * Use to annotate config
     */
    comment?: string
    /**
     * Tracked events specifications. Event configs targets must appear to be
     * descendants of root element defined by `iframe` and
     * `rootSelector` if they are specified.
     */
    events: EventConfig[]
}

/**
 * Dictionary mapping screen name to an array of strings or regex against
 * which location pathname is matched to determine current
 * screen for the event payload data
 *
 * @example
 * ```
 * {
        home: ['^/$']
    }
 * ```
 */
export interface Screens {
    [screen: string]: (string | RegExp)[]
}

type LoggerConfig = {
    silent?: boolean
}

/**
 * Additional external endpoint to which events will be sent.
 * The fraction determines the percentage of users for whom the sending will be carried out.
 */
export type AdditionalEventEndpoint =
    | {
          name: string
          baseURL: string
          getURL: string
          postURL?: undefined
          unloadEventURL?: string
          urlTrigger?: UrlTrigger[]
          fraction: Fraction
          triggerPattern?: PatternSequence
          forceInitialEventsSending?: boolean
      }
    | {
          name: string
          baseURL: string
          getURL?: undefined
          postURL: string
          unloadEventURL?: string
          urlTrigger?: UrlTrigger[]
          fraction: Fraction
          triggerPattern?: PatternSequence
          forceInitialEventsSending?: boolean
      }
    | {
          name: string
          baseURL: string
          getURL: string
          postURL: string
          unloadEventURL?: string
          urlTrigger?: UrlTrigger[]
          fraction: Fraction
          triggerPattern?: PatternSequence
          forceInitialEventsSending?: boolean
      }


export type PageResolver = (event: TrackerEvent) => string | null

type DataAttrFilter = (attrName: string) => boolean
type DataAttrsWhiteListItem = (RegExp | string | DataAttrFilter)
export type DataAttrsWhiteList = DataAttrsWhiteListItem[]

/**
 * User defined tracker configuration
 */
export interface ClientConfig {
    /**
     * Source name send with events
     */
    source: string
    /**
     * Event collection with custom events specifications
     */
    collections?: EventCollection[]

    /**
     * Plugins collection
     */
    plugins?: InstallPluginFunc[]

    /**
     * Collection of configuration objects
     * for monitoring DOM changes
     * Content will be sent to the dom_info field with event_name = name
     */
    domObservers?: DomCollectorTarget[]

    collectDataAttrsFraction?: Fraction | null
    dataAttrsWhiteList?: DataAttrsWhiteList | null
    /**
     * Dictionary mapping screen name to an array of strings or regex against
     * which location pathname is matched to determine current
     * screen for the event payload data
     */
    screens?: Screens


    pageResolver?: PageResolver | null
    /**
     * Yandex counter ID to perform basic sync on page load
     */
    yaCounter?: number | null
    baseEndpointFraction?: Fraction | null
    inspectConfigurationFraction?: Fraction | null
    sessionEventsFraction?: Fraction | null
    forceInitialEventsSending?: boolean | null
    baseEndpointTriggerPattern?: PatternSequence | null
    markEventFraction?: number | null
    collectCookies?: string[]
    swipeTrackerConfig?: SwipeTrackerConfig

    /**
     * If a GET-parameter from this list appears in the user's URL, sending events to the base endpoint is activated
     */
    urlTrigger?: UrlTrigger[]

    /**
     * Additional external endpoints to which events will be sent.
     */
    additionalEndpoints?: AdditionalEventEndpoint[]
    /**
     * If `defaultSendValue` is specified and `sendValue` on the event config
     * not, `sendValue` will be inherited from `defaultSendValue`. If not
     * specified, then `defaultConfig`.`defaultSendValue` will be used.
     */
    defaultSendValue?: SendValue | null
    /**
     * Modify events. You can add or change new event properties here
     * (for example, you can send cookies with it)
     */
    modifyEvents?: EventModifier | null
    /**
     * Hook called on `padeload` event.
     */
    onPageLoad?: PageLoadHook | null

    onVisibleEvents?: EventConfig[]
    logger?: LoggerConfig
    remoteConfigSiteId?: number | null
}

/**
 * Default tracker config grasping set of common interactive elements, i.e.
 * `button`, `a`, `input`, `textarea`, `select`, `form`
 */
export const defaultConfig: Config = {
    source: "test",
    yaCounter: null,
    markEventFraction: null,
    forceInitialEventsSending: false,
    sessionEventsFraction: {
        rete: 0,
        ga: 0,
        ya: 0,
    },
    collectDataAttrsFraction: {
        rete: 0,
        ga: 0,
        ya: 0,
    },
    dataAttrsWhiteList: null,
    remoteConfigSiteId: null,
    collectCookies: [],
    inspectConfigurationFraction: {
        rete: 0,
        ga: 0,
        ya: 0,
    },
    baseEndpointFraction: {
        rete: 1.0,
        ga: 1.0,
        ya: 1.0,
    },
    baseEndpointTriggerPattern: null,
    urlTrigger: [],
    additionalEndpoints: [],
    screens: {
        home: ["^/$"],
    },
    pageResolver: null,
    defaultSendValue: "hashOfValue",
    modifyEvents: null,
    onPageLoad: null,
    plugins: [],
    onVisibleEvents: [],
    domObservers: [],
    swipeTrackerConfig: {
        collections: [],
    },
    collections: [
        {
            rootSelector: "body",
            events: [
                {
                    isDefault: true,
                    selector: "button",
                    sendValue: "plainValue",
                },
                {
                    isDefault: true,
                    selector: "a",
                    name: "link_click",
                    sendValue: "plainValue",
                },
                {
                    isDefault: true,
                    selector: "a",
                    name: "link_rightclick",
                    eventType: "contextmenu",
                    sendValue: "plainValue",
                },
                {
                    isDefault: true,
                    selector: "input",
                    sendValue: "hashOfValue",
                },
                {
                    isDefault: true,
                    selector: "input",
                    eventType: "change",
                    sendValue: "hashOfValue",
                },
                {
                    isDefault: true,
                    selector: "textarea",
                    sendValue: "hashOfValue",
                },
                {
                    isDefault: true,
                    selector: "textarea",
                    eventType: "change",
                    sendValue: "hashOfValue",
                },
                {
                    isDefault: true,
                    selector: "select",
                    sendValue: "plainValue",
                },
                {
                    isDefault: true,
                    selector: "select",
                    eventType: "change",
                    sendValue: "plainValue",
                },
                {
                    isDefault: true,
                    selector: "form",
                    eventType: "submit",
                    sendValue: "plainValue",
                },
                {
                    isDefault: true,
                    selector: "*",
                    eventType: "click",
                    name: "something_clicked",
                    eventCustomName: "something_clicked",
                    sendValue: "plainValue",
                },
            ],
        },
    ],
    logger: {
        silent: false,
    },
}

const DEBUG_COLLECTION_KEY = "rete-tracker-debug-collection"

export const injectDebugCollection = (collection: EventCollection): void => {
    window.localStorage.setItem(DEBUG_COLLECTION_KEY, JSON.stringify(collection))
}

export const removeDebugCollection = () => {
    window.localStorage.removeItem(DEBUG_COLLECTION_KEY)
}

export const getDebugCollection = (): EventCollection | null => {
    const collection = window.localStorage.getItem(DEBUG_COLLECTION_KEY)
    if (!collection) return null
    try {
        return JSON.parse(collection) as EventCollection
    } catch (err) {
        return null
    }
}


/**
 * Internal tracker config used in tracker
 * @class
 */
export class Config implements Required<ClientConfig> {
    collections!: EventCollection[]
    screens!: Screens
    source!: string
    yaCounter!: number | null
    forceInitialEventsSending!: boolean
    collectDataAttrsFraction!: Fraction | null
    inspectConfigurationFraction!: Fraction | null
    baseEndpointFraction!: Fraction | null
    sessionEventsFraction!: Fraction | null
    baseEndpointTriggerPattern!: PatternSequence | null
    urlTrigger!: UrlTrigger[]
    dataAttrsWhiteList!: DataAttrsWhiteList | null
    markEventFraction!: number | null
    defaultSendValue!: SendValue | null
    remoteConfigSiteId!: number | null
    modifyEvents!: EventModifier | null
    onPageLoad!: PageLoadHook | null
    onVisibleEvents!: EventConfig[]
    swipeTrackerConfig!: SwipeTrackerConfig
    plugins!: InstallPluginFunc[]
    domObservers!: DomCollectorTarget[]
    pageResolver!: PageResolver | null
    additionalEndpoints!: AdditionalEventEndpoint[]
    logger!: LoggerConfig
    collectCookies!: string[]

    constructor(clientConfig: ClientConfig) {
        if (clientConfig) {
            const collections = clientConfig.collections || []
            const swipeTrackerConfig = clientConfig.swipeTrackerConfig
            const swipeTrackerCollections = swipeTrackerConfig && swipeTrackerConfig.collections
                ? swipeTrackerConfig.collections
                : []
            const dataAttrsWhiteList = clientConfig.dataAttrsWhiteList || []

            Object.assign(this, {
                source:
                    clientConfig.source ||
                    document.location.host.replace(/[^a-z0-9]/g, "") ||
                    "test",
                remoteConfigSiteId: typeof clientConfig.remoteConfigSiteId === "number"
                    ? clientConfig.remoteConfigSiteId
                    : defaultConfig.remoteConfigSiteId,
                yaCounter: clientConfig.yaCounter || null,
                collections: collections.concat(defaultConfig.collections),
                swipeTrackerConfig: {
                    collections: swipeTrackerCollections.concat(
                        defaultConfig.swipeTrackerConfig.collections || [],
                    ),
                },
                collectDataAttrsFraction: (
                    clientConfig.collectDataAttrsFraction || defaultConfig.collectDataAttrsFraction
                ),
                dataAttrsWhiteList: dataAttrsWhiteList.concat(
                    defaultConfig.dataAttrsWhiteList || []
                ),
                collectCookies: defaultConfig.collectCookies.concat(
                    clientConfig.collectCookies || []
                ),
                // eslint-disable-next-line max-len
                forceInitialEventsSending:
                    typeof clientConfig.forceInitialEventsSending === "boolean"
                        ? Boolean(clientConfig.forceInitialEventsSending)
                        : Boolean(defaultConfig.forceInitialEventsSending),
                baseEndpointFraction:
                    typeof clientConfig.baseEndpointFraction === "object"
                        ? clientConfig.baseEndpointFraction
                        : defaultConfig.baseEndpointFraction,
                sessionEventsFraction:
                    typeof clientConfig.sessionEventsFraction === "object"
                        ? clientConfig.sessionEventsFraction
                        : defaultConfig.sessionEventsFraction,
                inspectConfigurationFraction:
                    typeof clientConfig.inspectConfigurationFraction === "object"
                        ? clientConfig.inspectConfigurationFraction
                        : defaultConfig.inspectConfigurationFraction,
                markEventFraction:
                    typeof clientConfig.markEventFraction === "number"
                        ? clientConfig.markEventFraction
                        : defaultConfig.markEventFraction,
                baseEndpointTriggerPattern:
                    clientConfig.baseEndpointTriggerPattern ||
                    defaultConfig.baseEndpointTriggerPattern,
                additionalEndpoints: defaultConfig.additionalEndpoints.concat(
                    clientConfig.additionalEndpoints || []
                ),
                domObservers: defaultConfig.domObservers.concat(
                    clientConfig.domObservers || []
                ),
                plugins: defaultConfig.plugins.concat(
                    clientConfig.plugins || []
                ),
                urlTrigger: defaultConfig.urlTrigger.concat(
                    clientConfig.urlTrigger || [],
                ),
                screens: Object.assign(
                    {},
                    defaultConfig.screens,
                    clientConfig.screens
                ),
                pageResolver: clientConfig.pageResolver || defaultConfig.pageResolver || null,
                defaultSendValue:
                    clientConfig.defaultSendValue ||
                    defaultConfig.defaultSendValue,
                modifyEvents:
                    clientConfig.modifyEvents || defaultConfig.modifyEvents,

                onPageLoad: clientConfig.onPageLoad || defaultConfig.onPageLoad,

                onVisibleEvents: defaultConfig.onVisibleEvents.concat(
                    clientConfig.onVisibleEvents || []
                ),
                logger: clientConfig.logger || defaultConfig.logger,
            })
        } else Object.assign(this, defaultConfig)
    }
}
