/**
 * Main entry point containing core of the tracker.
 *
 * Is also exported as package.
 * @packageDocumentation
 */
import "intersection-observer"
import "./polyfills"
import { ClientConfig } from "./Config"
import { Tracker, TrackerInitHandler } from "./Tracker"

export interface InitReteTrackerParams {
    config: ClientConfig
    trackApiBaseUrl?: string
    onInit?: TrackerInitHandler
    debugMode?: boolean
    foundConfigEndpoint?: string
    analyticsToolsApiUrl?: string
}

const win = window as any

export function initReteTracker(params: InitReteTrackerParams) {
    const { config, trackApiBaseUrl, onInit, debugMode, foundConfigEndpoint, analyticsToolsApiUrl } = params

    if (win.__RETE_INIT__ === true) {
        // TODO: make alert
        return
    } 

    const tracker = new Tracker(
        config,
        trackApiBaseUrl || process.env.API_URL,
        foundConfigEndpoint || process.env.FOUND_CONFIG_ENDPOINT,
        analyticsToolsApiUrl || process.env.ANALYTICS_TOOLS_API_URL,
        onInit,
        debugMode
    )

    win.__RETE_INIT__ = true

    return tracker
}

/**
 * Exporting the init func & Tracker class
 * as global variable for usage without npm
 */
window.initReteTracker = initReteTracker
window.ReteTracker = Tracker
