import { cssPropertiesWhiteList } from "./css-properties-white-list"
import { domAttributesWhiteList } from "./dom-attributes-white-list"

import { uuid } from "../uuid"

export type VirtualTextNode = {
  node_type: "text"
  is_target: boolean
  id: string
  text: string
}

export type VirtualElementNode = {
  node_type: "element"
  is_target: boolean
  id: string
  attributes: object
  tag_name: string
  css_computed_style: object
  children: VirtualNode[]
}

export type VirtualNode = VirtualTextNode | VirtualElementNode


const getComputedStyles = (element: Element) => {
    const comutedStyles = window.getComputedStyle(element)
    const filtredProperpies: { [key: string]: string } = {}

    for (const propertyName of cssPropertiesWhiteList) {
        const value = comutedStyles.getPropertyValue(propertyName)
        if (value) {
            filtredProperpies[propertyName] = value
        }
    }

    return filtredProperpies
}

const getAttributes = (element: Element) => {
    const attrs: { [key: string]: string } = {}
    for (const attrName of domAttributesWhiteList) {
        const attrValue = element.getAttribute(attrName)
        if (attrValue) {
            attrs[attrName] = attrValue
        }
    }

    return attrs
}


const createVirtualNode = (currentNode: Node, targetNode?: Node): VirtualNode | null => {
    if (currentNode instanceof Element) {
        return {
            node_type: "element",
            is_target: Boolean(targetNode) && targetNode === currentNode, 
            id: uuid(),
            tag_name: currentNode.tagName,
            attributes: getAttributes(currentNode),
            css_computed_style: getComputedStyles(currentNode),
            children: [] as VirtualNode[],
        }
    }

    if (currentNode instanceof Text && currentNode.wholeText.trim()) {
        return {
            node_type: "text",
            is_target: Boolean(targetNode) && targetNode === currentNode, 
            id: uuid(),
            text: currentNode.wholeText,
        }
    }

    return null
}

type VirtualDomInfo = {
    elementNodesCount: number
    textNodesCount: number
    nestingLevel: number
    stoppedByLimit: boolean
}

export type Options = {
    elementNodesLimit?: number
    textNodesLimit?: number
    nestingLevelLimit?: number
}

const initialDomInfo = (): VirtualDomInfo => ({
    elementNodesCount: 0,
    textNodesCount: 0,
    nestingLevel: 0,
    stoppedByLimit: false,
})

export const getVirtualDom = (
    currentNode: Node,
    options?: Options,
    domInfo = initialDomInfo(),
    targetNode?: Node,
): VirtualNode | null => {
    const virtualNode = createVirtualNode(currentNode, targetNode)

    if (!virtualNode) {
        return null
    }

    if (virtualNode.node_type === "element") {
        domInfo.elementNodesCount += 1
    }

    if (virtualNode.node_type === "text") {
        domInfo.textNodesCount += 1
    }

    if (options && options.elementNodesLimit) {
        if (options.elementNodesLimit < domInfo.elementNodesCount) {
            domInfo.stoppedByLimit = true
            return null
        }
    }

    if (options && options.textNodesLimit) {
        if (options.textNodesLimit < domInfo.textNodesCount) {
            domInfo.stoppedByLimit = true
            return null
        }
    }

    if (options && options.nestingLevelLimit) {
        if (options.nestingLevelLimit < domInfo.nestingLevel) {
            domInfo.stoppedByLimit = true
            return null
        }
    }

    if (virtualNode.node_type === "text") {
        return virtualNode
    }

    if (currentNode.childNodes.length) {
        domInfo.nestingLevel += 1
    }

    for (const childNode of currentNode.childNodes) {
        const childVirtualNode = getVirtualDom(childNode, options, domInfo, targetNode)
        if (childVirtualNode) {
            virtualNode.children.push(childVirtualNode)
        }
    }

    return virtualNode
}

export const createVirtualDom = (rootEl: Node, options?: Options, targetNode?: Node) => {
    const domInfo: VirtualDomInfo = {
        elementNodesCount: 0,
        textNodesCount: 0,
        nestingLevel: 0,
        stoppedByLimit: false,
    }

    const virtualDom = getVirtualDom(rootEl, options, domInfo, targetNode)

    return {
        virtualDom,
        domInfo,
    }
}
