import { waitFor } from "./lib/wait-for"
import { waitForCookie } from "./cookie"
/**
 * Defines common utility functions.
 * @packageDocumentation
 */

export function onDOMContentLoaded(callback: Function): void {
    if (document.readyState == "loading") {
        document.addEventListener("DOMContentLoaded", () => callback())
    } else {
        callback()
    }
}

export function onWindowLoaded(callback: Function): void {
    if (document.readyState != "complete") {
        window.addEventListener("load", () => callback())
    } else {
        callback()
    }
}

/**
 * [Type guard function](https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types)
 * to test if object is instance of supplied constructor
 * @param obj object to test
 * @param prototype constructor function
 * @returns {void}
 */
export function instanceOf<T>(obj: any, prototype: new () => T): obj is T {
    return obj instanceof prototype
}

export function getConstructorChain(target: any) {
    const constructors: string[] = []
    try {
        function getConstructor(currentTarget: any) {
            const constructorName = currentTarget.constructor.name
            constructors.push(constructorName)
    
            if (constructorName !== "Function" && constructorName !== "Object") {
                getConstructor(Object.getPrototypeOf(currentTarget))
            }
        }
        getConstructor(Object.getPrototypeOf(target))
    } catch (err) {
        //
    }
    return constructors
}

export function isStrangeElement(target: EventTarget | null) {
    if (target instanceof Element) {
        return false
    }
    const constructors = getConstructorChain(target).reverse()
    return (
        constructors[0] === "Object" &&
        constructors[1] === "EventTarget" &&
        constructors[2] === "Node" &&
        constructors[3] === "Element"
    )
}

// Pre-generate crc32 polynomial lookup table
// http://wiki.osdev.org/CRC32#Building_the_Lookup_Table
// ... Actually use Alex's because it generates the correct bit order
//     so no need for the reversal function
const crc32_lookup_table = new Uint32Array(256)
for (let i = 256; i--; ) {
    let tmp = i
    for (let k = 8; k--; ) {
        tmp = tmp & 1 ? 3988292384 ^ (tmp >>> 1) : tmp >>> 1
    }
    crc32_lookup_table[i] = tmp
}

// crc32b
// Example input        : [97, 98, 99, 100, 101] (Uint8Array)
// Example output       : 2240272485 (Uint32)
export function crc32(data: string): number {
    let crc = -1 // Begin with all bits set ( 0xffffffff )
    for (let i = 0, l = data.length; i < l; i++) {
        crc = (crc >>> 8) ^ crc32_lookup_table[(crc & 255) ^ data.charCodeAt(i)]
    }
    return (crc ^ -1) >>> 0 // Apply binary NOT
}

// Fast MD5 implementation, copied from
// http://www.myersdaily.org/joseph/javascript/md5-text.html

let add32 = (a: number, b: number): number => {
    return (a + b) & 0xffffffff
}

// defining a lot of internal functions

// see later for add32--it needs to be improved

/* we are going to fix it now */
/* see the end! */

function cmn(
    q: number,
    a: number,
    b: number,
    x: number,
    s: number,
    t: number
): number {
    a = add32(add32(a, q), add32(x, t))
    return add32((a << s) | (a >>> (32 - s)), b)
}

function ff(
    a: number,
    b: number,
    c: number,
    d: number,
    x: number,
    s: number,
    t: number
): number {
    return cmn((b & c) | (~b & d), a, b, x, s, t)
}

function gg(
    a: number,
    b: number,
    c: number,
    d: number,
    x: number,
    s: number,
    t: number
): number {
    return cmn((b & d) | (c & ~d), a, b, x, s, t)
}

function hh(
    a: number,
    b: number,
    c: number,
    d: number,
    x: number,
    s: number,
    t: number
): number {
    return cmn(b ^ c ^ d, a, b, x, s, t)
}

function ii(
    a: number,
    b: number,
    c: number,
    d: number,
    x: number,
    s: number,
    t: number
): number {
    return cmn(c ^ (b | ~d), a, b, x, s, t)
}

function md5cycle(x: number[], k: number[]): void {
    let a: number = x[0],
        b: number = x[1],
        c: number = x[2],
        d: number = x[3]

    a = ff(a, b, c, d, k[0], 7, -680876936)
    d = ff(d, a, b, c, k[1], 12, -389564586)
    c = ff(c, d, a, b, k[2], 17, 606105819)
    b = ff(b, c, d, a, k[3], 22, -1044525330)
    a = ff(a, b, c, d, k[4], 7, -176418897)
    d = ff(d, a, b, c, k[5], 12, 1200080426)
    c = ff(c, d, a, b, k[6], 17, -1473231341)
    b = ff(b, c, d, a, k[7], 22, -45705983)
    a = ff(a, b, c, d, k[8], 7, 1770035416)
    d = ff(d, a, b, c, k[9], 12, -1958414417)
    c = ff(c, d, a, b, k[10], 17, -42063)
    b = ff(b, c, d, a, k[11], 22, -1990404162)
    a = ff(a, b, c, d, k[12], 7, 1804603682)
    d = ff(d, a, b, c, k[13], 12, -40341101)
    c = ff(c, d, a, b, k[14], 17, -1502002290)
    b = ff(b, c, d, a, k[15], 22, 1236535329)

    a = gg(a, b, c, d, k[1], 5, -165796510)
    d = gg(d, a, b, c, k[6], 9, -1069501632)
    c = gg(c, d, a, b, k[11], 14, 643717713)
    b = gg(b, c, d, a, k[0], 20, -373897302)
    a = gg(a, b, c, d, k[5], 5, -701558691)
    d = gg(d, a, b, c, k[10], 9, 38016083)
    c = gg(c, d, a, b, k[15], 14, -660478335)
    b = gg(b, c, d, a, k[4], 20, -405537848)
    a = gg(a, b, c, d, k[9], 5, 568446438)
    d = gg(d, a, b, c, k[14], 9, -1019803690)
    c = gg(c, d, a, b, k[3], 14, -187363961)
    b = gg(b, c, d, a, k[8], 20, 1163531501)
    a = gg(a, b, c, d, k[13], 5, -1444681467)
    d = gg(d, a, b, c, k[2], 9, -51403784)
    c = gg(c, d, a, b, k[7], 14, 1735328473)
    b = gg(b, c, d, a, k[12], 20, -1926607734)

    a = hh(a, b, c, d, k[5], 4, -378558)
    d = hh(d, a, b, c, k[8], 11, -2022574463)
    c = hh(c, d, a, b, k[11], 16, 1839030562)
    b = hh(b, c, d, a, k[14], 23, -35309556)
    a = hh(a, b, c, d, k[1], 4, -1530992060)
    d = hh(d, a, b, c, k[4], 11, 1272893353)
    c = hh(c, d, a, b, k[7], 16, -155497632)
    b = hh(b, c, d, a, k[10], 23, -1094730640)
    a = hh(a, b, c, d, k[13], 4, 681279174)
    d = hh(d, a, b, c, k[0], 11, -358537222)
    c = hh(c, d, a, b, k[3], 16, -722521979)
    b = hh(b, c, d, a, k[6], 23, 76029189)
    a = hh(a, b, c, d, k[9], 4, -640364487)
    d = hh(d, a, b, c, k[12], 11, -421815835)
    c = hh(c, d, a, b, k[15], 16, 530742520)
    b = hh(b, c, d, a, k[2], 23, -995338651)

    a = ii(a, b, c, d, k[0], 6, -198630844)
    d = ii(d, a, b, c, k[7], 10, 1126891415)
    c = ii(c, d, a, b, k[14], 15, -1416354905)
    b = ii(b, c, d, a, k[5], 21, -57434055)
    a = ii(a, b, c, d, k[12], 6, 1700485571)
    d = ii(d, a, b, c, k[3], 10, -1894986606)
    c = ii(c, d, a, b, k[10], 15, -1051523)
    b = ii(b, c, d, a, k[1], 21, -2054922799)
    a = ii(a, b, c, d, k[8], 6, 1873313359)
    d = ii(d, a, b, c, k[15], 10, -30611744)
    c = ii(c, d, a, b, k[6], 15, -1560198380)
    b = ii(b, c, d, a, k[13], 21, 1309151649)
    a = ii(a, b, c, d, k[4], 6, -145523070)
    d = ii(d, a, b, c, k[11], 10, -1120210379)
    c = ii(c, d, a, b, k[2], 15, 718787259)
    b = ii(b, c, d, a, k[9], 21, -343485551)

    x[0] = add32(a, x[0])
    x[1] = add32(b, x[1])
    x[2] = add32(c, x[2])
    x[3] = add32(d, x[3])
}

const md5blks: number[] = []
function md5blk(s: string): number[] {
    for (let i = 0; i < 64; i += 4) {
        md5blks[i >> 2] =
            s.charCodeAt(i) +
            (s.charCodeAt(i + 1) << 8) +
            (s.charCodeAt(i + 2) << 16) +
            (s.charCodeAt(i + 3) << 24)
    }
    return md5blks
}

function md51(s: string): number[] {
    const n: number = s.length,
        state: number[] = [1732584193, -271733879, -1732584194, 271733878]
    let i: number

    for (i = 64; i <= s.length; i += 64) {
        // blah with s.substring(i-16, i)
        md5cycle(state, md5blk(s.substring(i - 64, i)))
    }

    s = s.substring(i - 64)
    const tail = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

    for (i = 0; i < s.length; i++)
        tail[i >> 2] |= s.charCodeAt(i) << (i % 4 << 3)

    tail[i >> 2] |= 0x80 << (i % 4 << 3)

    if (i > 55) {
        md5cycle(state, tail)
        for (i = 0; i < 16; i++) tail[i] = 0
    }

    tail[14] = n * 8
    md5cycle(state, tail)

    return state
}

const hexChr = "0123456789abcdef"

function rhex(n: number): string {
    let s = ""
    for (let j = 0; j < 4; j++)
        s += hexChr[(n >> (j * 8 + 4)) & 0x0f] + hexChr[(n >> (j * 8)) & 0x0f]
    return s
}

function hex(x: number[]): string {
    const r: string[] = []
    for (let i = 0; i < x.length; i++) r.push(rhex(x[i]))
    return r.join("")
}

/**
 * Calculate MD5 hash of string.
 * @param str input string
 * @return {string} MD5 hash of string
 */
export function md5(str: string): string {
    return hex(md51(str))
}

/* this function is much faster, if possible we use it. Some IEs are
 * the only ones I know of that need the second function, by an if clause. */

if (md5("hello") != "5d41402abc4b2a76b9719d911017c592") {
    add32 = (x: number, y: number): number => {
        const lsw: number = (x & 0xffff) + (y & 0xffff),
            msw: number = (x >> 16) + (y >> 16) + (lsw >> 16)
        return (msw << 16) | (lsw & 0xffff)
    }
}

export function waitForGoogleAnalytics() {
    return waitFor(() => Boolean(window.ga))
        .then(() => {
            return new Promise(resolve => window.ga(resolve))
        })
        .then(() => waitForCookie("_ga"))
}

export function bytesLength(str: string) {
    return new Blob([str]).size
}

export function firstBytes(str: string, maxBytes: number) {
    const chrs: string[] = []

    for (const ch of str) {
        const bytes = bytesLength(chrs.join("") + ch)
        if (bytes <= maxBytes) {
            chrs.push(ch)
        } else {
            break
        }
    }

    return chrs.join("")
}


export function randomInt(min: number, max: number) {
    const rand = min - 0.5 + Math.random() * (max - min + 1)
    return Math.round(rand)
}
