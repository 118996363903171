export const browser = (): string => {
    const test = (regexp: RegExp) => regexp.test(window.navigator.userAgent)
    switch (true) {
        case test(/edge/i):
            return "edge"
        case test(/opr/i) && (!!window.opr || !!window.opera):
            return "opera"
        case test(/chrome/i) && !!window.chrome:
            return "chrome"
        case test(/trident/i):
            return "ie"
        case test(/firefox/i):
            return "firefox"
        case test(/safari/i):
            return "safari"
        default:
            return "other"
    }
}
