const getCoords = (el: HTMLElement) => {
    const box = el.getBoundingClientRect()

    return {
        top: box.top + pageYOffset,
        left: box.left + pageXOffset
    }
}

export const highlightElement = (el: HTMLElement, text: string) => {
    const prevBorder = el.style.border
    const { top, left } = getCoords(el)
    el.style.border = "2px dashed red"

    const tooltip = document.createElement("div")
    tooltip.innerHTML = text

    tooltip.style.background = "#272822"
    tooltip.style.color = "#f8f8f2"
    tooltip.style.fontSize = "16px"
    tooltip.style.padding = "8px"
    tooltip.style.position = "absolute"
    tooltip.style.borderRadius = "3px"
    tooltip.style.top = "0"
    tooltip.style.left = "0"
    tooltip.style.zIndex = "999999999999"
    tooltip.style.transform = `translate(${left}px,${top - 40}px)`
    document.body.appendChild(tooltip)

    return () => {
        el.style.border = prevBorder
        document.body.removeChild(tooltip)
    }
}
