import Observable from "zen-observable"

type Direction = "left" | "right" | "up" | "down" | "none"

type Target = {
  selector: string
  eventName: string
  eventCustomName?: string
  directions?: Direction[]
}

type SwipeConfig = {
  threshold?: number
  restraint?: number
  allowedTime?: number
}

type Collection = {
  rootSelector?: string
  swipeConfig?: SwipeConfig
  targets: Target[]
}

export type SwipeTrackerConfig = {
  collections: Collection[]
}

type HandleSwipeCbParams = {
  target: Target
  eventTarget: Element
  direction: Direction
}

type HandleSwipeCb = (params: HandleSwipeCbParams) => void

type Unwatch = () => void

const handleSwipe = (
    { rootSelector, targets, swipeConfig = {} }: Collection,
    cb: HandleSwipeCb = () => undefined,
): Unwatch | null => {

    const touchsurface = rootSelector
        ? document.querySelector(rootSelector)
        : document

    if (!touchsurface) return null

    let swipedir: Direction = "none"
    const { threshold = 150, restraint = 100, allowedTime = 1000 } = swipeConfig

    let startX: number
    let startY: number 
    let startTime: number


    const onTouchStart = (event: Event) => {
        // TODO: typehack! fix me
        const e = event as unknown as TouchEvent
        const touchobj = e.changedTouches[0]
        swipedir = "none"
        startX = touchobj.pageX
        startY = touchobj.pageY
        startTime = new Date().getTime() // record time when finger first makes contact with surface
    }

    const onTouchEnd = (event: Event) => {
        // TODO: typehack! fix me
        const e = event as unknown as TouchEvent
        const touchobj = e.changedTouches[0]
        const distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
        const distY = touchobj.pageY - startY // get vertical dist traveled by finger while in contact with surface
        const elapsedTime = new Date().getTime() - startTime // get time elapsed
        if (elapsedTime <= allowedTime) { // first condition for awipe met
            if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) { // 2nd condition for horizontal swipe met
                swipedir = (distX < 0) ? "left" : "right" // if dist traveled is negative, it indicates left swipe
            }
            else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint) { // 2nd condition for vertical swipe met
                swipedir = (distY < 0) ? "up" : "down" // if dist traveled is negative, it indicates up swipe
            }
        }
      
        if (swipedir === "none") return
      
        for (const target of targets) {
            if (!(event.target instanceof Element)) continue
            if (!Boolean(event.target.closest(target.selector))) continue
            if (target.directions && !target.directions.includes(swipedir)) continue
      
            cb({
                eventTarget: event.target,
                target,
                direction: swipedir,
            })
        }
    }


    touchsurface.addEventListener("touchstart", onTouchStart, false)
    touchsurface.addEventListener("touchend", onTouchEnd, false)

    return () => {
        touchsurface.removeEventListener("touchstart", onTouchStart, false)
        touchsurface.removeEventListener("touchend", onTouchEnd, false)
    }
}


export const createSwipeTracker = ({ collections }: SwipeTrackerConfig) => {
    return new Observable<HandleSwipeCbParams>((observer) => {
        const unwatchers: Unwatch[] = []

        for (const collection of collections) {
            const unwatch = handleSwipe(collection, (e) => {
                observer.next(e)
            })
            if (unwatch) unwatchers.push(unwatch)
        }

        return () => {
            unwatchers.forEach((unwatch) => unwatch())
        }
    })
}
