export const applyPageFilter = (filter?: RegExp | string) => {
    if (!filter) return true

    const url = window.location.href

    if (typeof filter === "string" && url.indexOf(filter) !== -1) {
        return true
    }

    if (filter instanceof RegExp && filter.test(url)) {
        return true
    }

    return false
}
