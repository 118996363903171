export const getRootParent = (element: Element, limit: number, currentDeepth = 0): Element => {
    if (element.tagName === "BODY") {
        return element
    }

    const parent = element.parentElement

    if (parent && currentDeepth <= limit) {
        return getRootParent(parent, limit, currentDeepth + 1) 
    }
    return element
}
