import { getRootParent } from "./get-root-parent"
import { createVirtualDom, Options as VirtualDomOptions } from "./virtual-dom"

type Options = VirtualDomOptions & {
    parentsCountLimit: number
}

export const getDomContext = (targetElement: Element, options: Options) => {
    const root = getRootParent(targetElement, options.parentsCountLimit)

    const { virtualDom, domInfo } = createVirtualDom(root, options, targetElement)

    return !domInfo.stoppedByLimit ? virtualDom : null 
}
