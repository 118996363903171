/**
 * window.postMessage API Observable wrapper
 */
import Observable from "zen-observable"

export type ListenParams = {
  eventType?: string
  once?: boolean
}

export interface Payload {
  type: string
}

export function listenMessages<T extends Payload = Payload>({
    eventType,
    once 
}: ListenParams) {
    return new Observable<MessageEvent<T>>((observer) => {
        const onMessage = (event: MessageEvent<T>) => {
            if (!eventType) {
                observer.next(event)
                if (once) {
                    observer.complete()
                }
            }

            if (
                eventType &&
            typeof event.data === "object" &&
            typeof event.data.type === "string" &&
            event.data.type === eventType) {
                observer.next(event)
                if (once) {
                    observer.complete()
                }
            }
        }

        window.addEventListener("message", onMessage)

        return () => window.removeEventListener("message", onMessage)
    })
}
