import { Request } from "./lib/Request"
import {
    createDomCollector,
    DomCollectorTarget,
} from "@retentioneering/retentioneering-dom-observer"
import { getCookie, writeCookie } from "./cookie"
import {
    Config,
    ClientConfig,
    EventConfig,
    Selector,
    EventCollection,
    SendValue,
    Fraction,
    AdditionalEventEndpoint,
    injectDebugCollection,
    removeDebugCollection,
    getDebugCollection,
} from "./Config"
import { EventFactory, CustomizablePayload } from "./EventFactory"
import { TrackerEvent } from "./Event"
import { createStatsManager, EventsStatsManager } from "./EventsStatsManager"
import { createEventsConfigsInspector, createTransportWorker } from "./EventsConfigsInspector"
import { Plugin } from "./Plugin"
import {
    onDOMContentLoaded,
    onWindowLoaded,
    instanceOf,
    crc32,
    md5,
    isStrangeElement,
} from "./utils"
import { Trigger, createTrigger } from "./lib/Trigger"
import { uuid } from "./lib/uuid"
import { joinURL, parseQueryParams } from "./lib/url"
import { createUrlTrigger, UrlTrigger, UrlTriggerInstance } from "./lib/UrlTrigger"
import { getRemoteConfig } from "./RemoteConfig"
import { listenSessionEvents } from "./UserSession"
import { useAnalyticsToolsApi } from "./AnalyticsToolsApi"
import { listenPageviewEvents } from "./Pageview"
import { createErrorTracker, ErrorTracker } from "./ErrorTracker"
import {
    isTopWindow,
    getParentWindowSessionId,
    listenGetParentWindowRequests,
} from "./JoinIFrameSession"
import { SwipeTrackerConfig, createSwipeTracker } from "./SwipeTracker"

/**
 * If cookie is not set - get user id from API and set it to cookie.
 * Invoke callback anyway.
 * @return {string} reuserid
 */
function getUserId(): string {
    const cookieName = "reuserid"
    const cookie = getCookie(cookieName)
    if (cookie) return cookie

    const userId = `${1e7}${-1e3}${-4e3}${-8e3}${-1e11}`.replace(/[10]/g, a =>
        (0 | (Math.random() * 16)).toString(16)
    )

    writeCookie(cookieName, userId)
    return userId
}

function generateSessionId(): string {
    return uuid()
}

const RETE_FORCE_TRACKING = "rete-force-tracking"

export function getEnabledAfterDownsampling(
    id: string | null,
    activatedFraction: number
): boolean {
    if (!id) {
        return false
    }
    const discretizator = 524309
    const groupId = (crc32(id) >>> 3) % discretizator
    const groupIdThreshold = activatedFraction * discretizator
    return groupId < groupIdThreshold
}

function forceTrackingEnabled(): boolean {
    const forceTrackingEnabled = getCookie(RETE_FORCE_TRACKING)
    return forceTrackingEnabled === "true"
}

function forceEventMarkingEnabled(): boolean {
    const forceEventMarkingEnabled = getCookie("rete-force-event-marking")
    return forceEventMarkingEnabled === "true"
}

function attachPerformanceFields(e: any): any {
    const p = performance as any
    if (p) {
        const n = p["navigation"]
        if (n) {
            e["redirect_count"] = n["redirectCount"] + 1
            const navigation_type = n["type"]
            switch (navigation_type) {
                case PerformanceNavigation.TYPE_NAVIGATE:
                    e["navigation_type"] = "Navigation"
                    break
                case PerformanceNavigation.TYPE_RELOAD:
                    e["navigation_type"] = "Reload"
                    break
                case PerformanceNavigation.TYPE_BACK_FORWARD:
                    e["navigation_type"] = "History"
                    break
                default:
                    e["navigation_type"] = `Unknown_${navigation_type}`
                    break
            }
        }
        const m = p["memory"]
        if (m) {
            e["used_js_heap_size"] =
                Math.floor(m["usedJSHeapSize"] / (1024 * 1024)) + 1
            e["total_js_heap_size"] =
                Math.floor(m["totalJSHeapSize"] / (1024 * 1024)) + 1
            e["js_heap_size_limit"] =
                Math.floor(m["jsHeapSizeLimit"] / (1024 * 1024)) + 1
        }
        const t = p["timing"]
        if (t) {
            e["pre_fetch_delay"] = t["fetchStart"] - t["navigationStart"] + 1
            e["domain_lookup_delay"] =
                t["domainLookupEnd"] - t["domainLookupStart"] + 1
            e["connect_delay"] = t["connectEnd"] - t["connectStart"] + 1
            e["request_delay"] = t["responseEnd"] - t["requestStart"] + 1
            if (t["domInteractive"]) {
                e["dom_interactive_delay"] =
                    t["domInteractive"] - t["domLoading"] + 1
            }
            if (t["domComplete"]) {
                e["dom_complete_delay"] = t["domComplete"] - t["domLoading"] + 1
            }
            if (t["loadEventEnd"]) {
                e["window_loaded_delay"] =
                    t["loadEventEnd"] - t["domLoading"] + 1
            }
            if (t["domContentLoadedEventEnd"]) {
                e["dom_content_loaded_listeners_delay"] =
                    t["domContentLoadedEventEnd"] -
                    t["domContentLoadedEventStart"] +
                    1
            }

            if (typeof t["navigationStart"] === "number") {
                e["navigation_start"] = t["navigationStart"]
            }
        }
    }
    return e
}

function isTrackingForbidden(): boolean {
    const isTrue = function(e: any): boolean {
        return e == true || e == "1" || e == "yes"
    }
    return (
        isTrue(window.doNotTrack) ||
        (navigator && isTrue(navigator.doNotTrack)) ||
        (navigator && isTrue((navigator as any).msDoNotTrack)) ||
        (window.external &&
            (window.external as any).msTrackingProtectionEnabled &&
            (window.external as any).msTrackingProtectionEnabled())
    )
}

/**
 * Callback invoked when tracker attached all event listeners and
 * before any event dispatch
 */
export interface TrackerInitHandler {
    /**
     * Configured tracker instance
     * @param tracker
     */
    (tracker: Tracker): void
}

/**
 * Callback called on event trigger
 */
export interface TrackerEventHandler {
    /**
     *
     * @param event tracker event thar is being sent
     * @param target element or window event happened on
     */
    (event: TrackerEvent, target: EventTarget): void
}

/**
 * Specifies global variables tracker will look at for init params
 */
export interface TrackerWindowEnv {
    /**
     * Custom config
     */
    reteConfig: ClientConfig
    /**
     * Variable tracker instance will be assigned to
     */
    reteTracker?: Tracker
    /**
     * Callback invoked when tracker attached all event listeners and
     * before any event dispatch
     * @remarks
     *
     * This callback is handy for subscribing on tracker events, i.g.:
     * ```
     * window.onReteTrackerReady = function(tracker): void {
     *     tracker.subscribe(function(event): void {
     *         // Do something on event
     *     });
     * }
     * ```
     */
    onReteTrackerReady?: TrackerInitHandler
    /**
     * Debug flag being passed to the tracker constructor
     */
    DEBUG_MODE?: boolean
    /**
     * API url events will be sent to
     */
    RETE_API_URL?: string
}

class DocumentVisibilityTracker {
    protected _onHiddenTimeout = 20000
    protected _onVisible: () => void
    protected _onHidden: () => void
    protected _wasVisible = true
    protected _timer: any = null

    constructor(onVisible: () => void, onHidden: () => void) {
        this._onVisible = onVisible
        this._onHidden = onHidden
        this.update = this.update.bind(this)
    }

    update(): void {
        const isVisible = document.visibilityState != "hidden"
        if (this._wasVisible == isVisible) return

        this._wasVisible = isVisible
        if (this._timer) {
            clearTimeout(this._timer)
        }
        if (isVisible) {
            if (!this._timer) {
                setTimeout(() => this._onVisible(), 0)
            }
            this._timer = null
        } else {
            this._timer = setTimeout(() => {
                this._timer = null
                this._onHidden()
            }, this._onHiddenTimeout)
        }
    }
}

function isInitialEvent(event: TrackerEvent) {
    return (
        event.event_name === "pageview" ||
        event.event_name === "tracker_created" ||
        event.event_name === "dom_content_loaded"
    )
}

class WindowFocusTracker {
    protected _onBlurTimeout = 20000
    protected _onFocus: () => void
    protected _onBlur: () => void
    protected _wasFocused = true
    protected _timer: any = null

    constructor(onFocus: () => void, onBlur: () => void) {
        this._onFocus = onFocus
        this._onBlur = onBlur
        this.update = this.update.bind(this)
    }

    update(): void {
        const isFocused = document.hasFocus()
        if (this._wasFocused == isFocused) return

        this._wasFocused = isFocused
        if (this._timer) {
            clearTimeout(this._timer)
        }
        if (isFocused) {
            if (!this._timer) {
                setTimeout(() => this._onFocus(), 0)
            }
            this._timer = null
        } else {
            this._timer = setTimeout(() => {
                this._timer = null
                this._onBlur()
            }, this._onBlurTimeout)
        }
    }
}

type SendEventParams = {
    event: TrackerEvent
    endpointName?: string 
    baseURL: string
    getEndpoint?: string
    postEndpoint?: string
}

type EndpointWithTrigger = AdditionalEventEndpoint & {
    trigger?: Trigger
}

type EventSendingCheckerParams = {
    endpointName?: string
    fraction?: Fraction | null
    eventFraction?: Fraction | null
    trigger?: Trigger | null
    isInitialEvent?: boolean
    forceInitialEventsSending?: boolean
}

type LastCollectParams = {
    [key: string]: string
}

type PageFilterCb = (cb: string) => any
const applyPageFilter = (pageFilter: string | RegExp | PageFilterCb) => {
    const href = window.location.href

    if (typeof pageFilter === "string") {
        return href === pageFilter
    }

    if (pageFilter instanceof RegExp) {
        return pageFilter.test(href)
    }

    return Boolean(pageFilter(href))
}

type EndpointEnabledDetails = {
    base_endpoint_enabled_by_rete_fraction: 1 | 0
    base_endpoint_enabled_by_ga_fraction: 1 | 0
    base_endpoint_enabled_by_ym_fraction: 1 | 0
    base_endpoint_enabled_by_trigger: 1 | 0
    base_endpoint_enabled_by_force: 1 | 0
    base_endpoint_enabled_by_url_parameter: 1 | 0
    base_endpoint_enabled_by_session_fraction: 1 | 0
}

const GET_PARENT_SESSION_ATTEMPT_MS = 200
const GET_PARENT_SESSION_TIMEOT_MS = 6000

type ObservedIframe = {
    element: HTMLIFrameElement
    collection: EventCollection
}

export class Tracker {
    /**
     * Use ID parsed from cookies or generated by the script
     */
    protected _doNotTrack: boolean = isTrackingForbidden()
    protected _forceTracking: boolean = forceTrackingEnabled()
    protected _forceEventMarking: boolean = forceEventMarkingEnabled()
    protected _urlTrigger: UrlTriggerInstance
    protected _errorTracker: ErrorTracker

    protected _listeners: any[] = []
    protected _observedIframes: ObservedIframe[] = []
    protected _pixelRequestsQueue: any[] = []
    protected _plugins: Plugin[] = []
    protected _endpoints: EndpointWithTrigger[] = []
    protected _baseEndpointTrigger: Trigger | null = null
    protected _lastObserversResults: LastCollectParams = {}

    protected _sessionId: string = generateSessionId()
    protected _parentWindowSessionId: string | null = null
    protected _parentWindowUserId: string | null = null
    protected _parentWindowNotResponding: boolean | null = null

    protected _userId: string = getUserId()
    protected _ym_uid: string | null = null
    protected _gid: string | null = null
    protected _ga: string | null = null

    protected _config: Config
    protected _url: string
    protected _configInspectorBaseUrl: string
    protected _analyticsToolsApiUrl: string
    protected _debugMode: boolean
    protected _eventFactory: EventFactory
    protected _eventsStatsManager: EventsStatsManager

    protected _documentVisibilityTracker?: DocumentVisibilityTracker
    protected _windowFocusTracker?: WindowFocusTracker
    protected _intersectionObserver?: IntersectionObserver
    protected _selectorToEventConfig: any = {}

    protected _subscribers: TrackerEventHandler[] = []

    protected _yaSync = false

    get userId(): string {
        return this._userId
    }

    get ym_uid(): string | null {
        if (!this._ym_uid) {
            this._ym_uid = getCookie("_ym_uid")
        }
        return this._ym_uid
    }

    get gid(): string | null {
        if (!this._gid) {
            const id = getCookie("_gid")
            if (id) this._gid = id.substring(6) // remove "GA1.2." prefix
        }
        return this._gid
    }

    get ga(): string | null {
        if (!this._ga) {
            const id = getCookie("_ga")
            if (id) this._ga = id.substring(6) // remove "GA1.2." prefix
        }
        return this._ga
    }

    get combinedUserId(): string {
        const r = this.userId || "none"
        const y = this.ym_uid || "none"
        const g = this.gid || "none"
        const a = this.ga || "none"
        return `${r}|${y}|${g}|${a}` // reuserx is added at server
    }

    /**
     * Internal tracker config received by merging client and default configs
     */
    get config(): Config {
        return this._config
    }

    get version(): string {
        return process.env.PACKAGE_VERSION || "__CIRCLE_SHA1__"
    }

    get silentMode(): boolean {
        return Boolean(this.config.logger && this.config.logger.silent)
    }

    constructor(
        config: ClientConfig,
        url: string,
        configInspectorBaseUrl: string,
        analyticsToolsApiUrl: string,
        onInit?: TrackerInitHandler,
        debugMode = false,
    ) {
        this._config = new Config(config)
        this._url = url
        this._configInspectorBaseUrl = configInspectorBaseUrl
        this._analyticsToolsApiUrl = analyticsToolsApiUrl
        this._debugMode = debugMode
        this._eventFactory = new EventFactory(
            this.config,
            this.version,
            () => this.combinedUserId,
            () => this._sessionId,
            () => this.ga,
            () => this.ym_uid,
        )
        this._eventsStatsManager = createStatsManager({ userId: this.userId })

        // binding in constructor instead of simply using arrow functions
        // because this allows to extend class in the future
        // TODO use ES7 arrow methods instead of .bind
        this._addEventListener = this._addEventListener.bind(this)
        this.uninstallListeners = this.uninstallListeners.bind(this)
        this.dispatchCustomEvent = this.dispatchCustomEvent.bind(this)
        this._handleEvent = this._handleEvent.bind(this)
        this._handleIntersect = this._handleIntersect.bind(this)
        this._sendEvent = this._sendEvent.bind(this)
        this._bindEvents = this._bindEvents.bind(this)
        this._bindEvent = this._bindEvent.bind(this)
        this._bindDomObservers = this._bindDomObservers.bind(this)
        this._bindSwipeTracker = this._bindSwipeTracker.bind(this)
        this._bindOnVisibleEvents = this._bindOnVisibleEvents.bind(this)
        this._errorTracker = createErrorTracker({
            onError: (err) => {
                this.dispatchCustomEvent({
                    "event_name": "load_resource_error",
                    "event_value": JSON.stringify(err),
                }, true)
            },
            filters: ["img"],
        })

        this._endpoints = this._config.additionalEndpoints.map(endpoint => {
            const { triggerPattern } = endpoint
            if (triggerPattern) {
                const trigger = createTrigger({
                    sequence: triggerPattern,
                    persistTriggeredState: true,
                })
                return { ...endpoint, trigger }
            }
            return endpoint
        })

        if (this._config.baseEndpointTriggerPattern) {
            this._baseEndpointTrigger = createTrigger({
                sequence: this._config.baseEndpointTriggerPattern,
                persistTriggeredState: true,
            })
        }

        const additionalEndpointsTriggers = this._config.additionalEndpoints
            .filter(({ urlTrigger }) => urlTrigger)
            .map(({ name, urlTrigger }) => ({
                name,
                // TODO: typehack! fix me
                urlTrigger: urlTrigger as unknown as UrlTrigger[],
            }))

        this._urlTrigger = createUrlTrigger({
            baseEndpointTrigger: this._config.urlTrigger,
            additionalEndpointsTriggers,
        })

        this._urlTrigger.init()
        this._urlTrigger.store()

        this.checkAndInitForceTracking()

        if (this._doNotTrack && !this._forceTracking) {
            this.log("Rete: disabled due to doNotTrack policy")
            return
        }

        this.log(
            "Rete: enabled" +
                `, version: ${this.version}` +
                `, url: ${this._url}` +
                `, mode: ${this._debugMode ? "debug" : "prod"}`
        )

        // 

        if (this._isSessionEventsSendingEnabled()) {
            const stream = listenSessionEvents(this)
            stream.subscribe((event) => {
                this.dispatchCustomEvent({
                    event_name: event.name,
                    event_value: event.reason,
                    params: document.referrer,
                    base_endpoint_enabled_by_session_fraction: 1,
                }, true, true)
            })
        }

        if (isTopWindow()) {
            listenGetParentWindowRequests({
                sessionId: this._sessionId,
                userId: this.combinedUserId,
            })
            this._initTracker(onInit)
        } else {
            getParentWindowSessionId({
                attemptIntervalMs: GET_PARENT_SESSION_ATTEMPT_MS,
                timeoutMs: GET_PARENT_SESSION_TIMEOT_MS,
            })
                .then(({ sessionId, userId }) => {
                    this._parentWindowSessionId = sessionId
                    this._parentWindowUserId = userId
                    this._parentWindowNotResponding = false
                    this._initTracker(onInit)
                })
                .catch(() => {
                    this._parentWindowNotResponding = true
                    this._initTracker(onInit)
                })
        }

    }

    public testFraction(id: string, fraction: number) {
        return getEnabledAfterDownsampling(id, fraction)
    }

    public forceTrackingEnabled() {
        return forceTrackingEnabled()
    }

    public getCurrentUserEventsCount() {
        return this._eventsStatsManager.getCurrentUserEventCount()
    }

    // public helper 
    public getAllDataAttrs(el?: Node) {
        const dataattrs: DOMStringMap[] = []
        const getTreeAttrs = (el: Node) => {

            if (el instanceof HTMLElement &&
                el.dataset &&
                Object.getOwnPropertyNames(el.dataset).length) {
                dataattrs.push(el.dataset)
            }
        
            for (const child of el.childNodes) {
                getTreeAttrs(child)
            }
        }

        getTreeAttrs(el || document)

        return dataattrs
    }

    public checkAndInitForceTracking() {
        // already enabled
        if (this._forceTracking) return
        try {
            const query = parseQueryParams(document.location.href)
            const forceTrackingEnabled =  query["forceReteTracking"] === "true"
            if (forceTrackingEnabled) {
                writeCookie(RETE_FORCE_TRACKING, "true")
                this._forceTracking = true
            }
        } catch (err) {
            this.log(err)
        }

    }

    _initTracker(onInit?: TrackerInitHandler) {
        for (const installPlugin of this._config.plugins) {
            try {
                this._plugins.push(installPlugin(this))
            } catch (err) {
                this.log("Rete: install plugin failed")
                this.log(err)
            }
        }

        // send created event
        this._sendSimleEvent("tracker_created")
        const pageviewEvents = listenPageviewEvents()
        pageviewEvents.subscribe((event) => {
            this._sendSimleEvent("pageview")
        })

        this._bindWindowUnloadEvent()

        try {
            this._errorTracker.collectErrors()
        } catch (err) {
            this.log(err)
        }


        if (onInit) {
            //TODO external code may throw an unexpected exception. Take out side effects from constructor
            try {
                onInit(this)
            } catch (err) {
                this.log(err)
            }
        }


        onDOMContentLoaded(() => {
            this._documentVisibilityTracker = new DocumentVisibilityTracker(
                () => this._sendSimleEvent("document_visible"),
                () => this._sendSimleEvent("document_hidden")
            )
            this._windowFocusTracker = new WindowFocusTracker(
                () => this._sendSimleEvent("window_has_focus"),
                () => this._sendSimleEvent("window_lost_focus")
            )
            this._intersectionObserver = new IntersectionObserver(
                entries => this._handleIntersect(entries),
                { threshold: 0.5 }
            )

            const debugCollection = getDebugCollection()

            const addDebugCollection = () => {
                if (debugCollection) {
                    this.config.collections.unshift(debugCollection)
                }
            }

            if (typeof this.config.remoteConfigSiteId === "number") {
                this._getRemoteConfig().then(() => {
                    addDebugCollection()
                    this._bindEvents(this.config.collections)
                    this._bindEventsConfigsInspector()
                }).catch((err) => {
                    addDebugCollection()
                    this._bindEvents(this.config.collections)
                    this._bindEventsConfigsInspector()
                    this.log(err)
                })
            } else {
                addDebugCollection()
                this._bindEvents(this.config.collections)
                this._bindEventsConfigsInspector()
            }

            this._bindDomObservers(this.config.domObservers)
            this._bindSwipeTracker(this.config.swipeTrackerConfig)
            this._bindOnVisibleEvents(this.config.onVisibleEvents)

            if (this._config.onPageLoad) {
                this._config.onPageLoad(this)
            }

            useAnalyticsToolsApi()

            this._addEventListener(
                document,
                "visibilitychange",
                () =>
                    this._documentVisibilityTracker &&
                    this._documentVisibilityTracker.update(),
                false
            )
            this._addEventListener(
                window,
                "focus",
                () =>
                    this._windowFocusTracker &&
                    this._windowFocusTracker.update(),
                false
            )
            this._addEventListener(
                window,
                "blur",
                () =>
                    this._windowFocusTracker &&
                    this._windowFocusTracker.update(),
                false
            )
            const scrollData = {
                prevY: window.scrollY,
                wasNotified: false,
            }
            this._addEventListener(
                window,
                "scroll",
                () => {
                    if (scrollData.wasNotified) return
                    if (scrollData.prevY == window.scrollY) return
                    scrollData.wasNotified = true
                    this._sendSimleEvent("first_vertical_scroll")
                },
                false
            )

            //TODO костыль для ожидания значения в performance.timing.domComplete
            setTimeout(() => this._sendPerfEvent("dom_content_loaded"), 200)
        })
        onWindowLoaded(() => {
            //TODO костыль для ожидания значения в performance.timing.loadEventEnd
            setTimeout(() => {
                this._sendPerfEvent("window_loaded")
                if (this._documentVisibilityTracker) {
                    this._documentVisibilityTracker.update()
                }
                if (this._windowFocusTracker) {
                    this._windowFocusTracker.update()
                }
            }, 200)
        })
    }

    _addEventListener(
        target: any,
        type: string,
        listener: any,
        useCapture: boolean
    ): void {
        const l = { e: target, t: type, f: listener, c: useCapture }
        l.e.addEventListener(l.t, l.f, l.c)
        this._listeners.push(l)
    }

    uninstallListeners(): void {
        if (
            this._intersectionObserver &&
            this._intersectionObserver.disconnect
        ) {
            //TODO experimental ?is it safe to use?
            this._intersectionObserver.disconnect()
        }
        this._listeners.forEach((l: any) => {
            l.e.removeEventListener(l.t, l.f, l.c)
        })
        this._listeners = []
    }

    /**
     * Subscribes listener for events from tracker
     * @param {TrackerEventHandler} listener
     * @return {void}
     */
    subscribe(listener: TrackerEventHandler): void {
        this._subscribers.push(listener)
    }

    /**
     * Unsubscribes listener from events
     * @public
     * @param {TrackerEventHandler} listener
     * @return {void}
     */
    unsubscribe(listener: TrackerEventHandler): void {
        this._subscribers = this._subscribers.filter(
            subscriber => subscriber !== listener
        )
    }

    protected _boolToNumber(val: boolean) {
        return val ? 1 : 0
    }

    /**
     * Dispatches custom event
     * @public
     * @param {CustomizablePayload} payload
     * @return {void}
     */
    dispatchCustomEvent(payload: CustomizablePayload, noHash = false, forceSending = false): void {
        this._sendEvent(
            this._eventFactory.createEvent({
                is_iframe: isTopWindow() ? 0 : 1,
                parent_window_session_id: this._parentWindowSessionId || undefined,
                parent_window_user_id: this._parentWindowUserId || undefined,
                parent_window_not_responding: this._parentWindowNotResponding !== null
                    ? this._boolToNumber(this._parentWindowNotResponding)
                    : undefined,
                ...this._baseEndpointEnabledDetails(),
                ...payload,
            }, undefined, undefined, noHash),
            window,
            forceSending,
        )
    }

    isBaseEndointEnabled() {
        return this._isEventSendingEnabled({
            fraction: this.config.baseEndpointFraction,
            trigger: this._baseEndpointTrigger,
        })
    }

    // TODO: implement logger with log levels
    log = (...args: any[]) => {
        if (!this.silentMode) {
            console.log(...args)
        }
    }

    protected _createSimpleEvent(name: string): TrackerEvent {
        return this._eventFactory.createEvent({
            event_name: name,
            event_value: document.title,
            event_auto: true,
            ...this._baseEndpointEnabledDetails(),
            is_iframe: isTopWindow() ? 0 : 1,
            parent_window_session_id: this._parentWindowSessionId || undefined,
            parent_window_user_id: this._parentWindowUserId || undefined,
            parent_window_not_responding: this._parentWindowNotResponding !== null
                ? this._boolToNumber(this._parentWindowNotResponding)
                : undefined,
            base_endpoint_options: {
                sendValue: "plainValue",
            },
        })
    }

    protected _sendSimleEvent(name: string): void {
        this._sendEvent(this._createSimpleEvent(name), window)
    }

    protected _sendPerfEvent(name: string): void {
        this._sendEvent(
            attachPerformanceFields(
                this._createSimpleEvent(name)
            ) as TrackerEvent,
            window
        )
    }

    /**
     * Collects event payload and sends it
     * @protected
     * @param {Event} event browser event
     * @param {EventConfig} item tracker event config
     * @param {boolean} root event is attached to root element
     * @param {Window} windowEnv window object associated with page
     * event happened on
     * @return {void}
     */
    protected _handleEvent(
        event: Event,
        item: EventConfig,
        root = false,
        windowEnv: Window = window
    ): void {
        try {
            const wasProcessed = (function(e: any): boolean {
                return e["reteWasProcessed"] ? true : false
            })(event)
            if (wasProcessed) return

            if (item.hasOwnProperty("pageFilter") && item.pageFilter) {
                const url = window.location.href
                if (typeof item.pageFilter === "string" && url.indexOf(item.pageFilter) === -1) {
                    return
                }

                if (item.pageFilter instanceof RegExp && !item.pageFilter.test(url)) {
                    return
                }
            }

            if (item.hasOwnProperty("textContentFilter") && item.textContentFilter) {
                const eventTarget = event.target
                
                if (eventTarget && (eventTarget as HTMLElement).textContent) {
                    const textContent = (eventTarget as HTMLElement).textContent as string


                    if (typeof item.textContentFilter === "string" &&
                        textContent.toLowerCase().indexOf(item.textContentFilter) === -1
                    ) {
                        return
                    }

                    if (item.textContentFilter instanceof RegExp &&
                        !item.textContentFilter.test(textContent)
                    ) {
                        return
                    }

                }
            }


            const eventTarget: Element = (event.target as Element)
            const eventCurrentTarget: Element = (event.currentTarget as Element)

            if (
                !instanceOf<Element>(event.target, windowEnv.Element) ||
                !instanceOf<Element>(event.currentTarget, windowEnv.Element)
            ) {
                if (!isStrangeElement(event.target)) {
                    return
                }
            }
            let target: Element
            if (root) {
                if (item.exclude && eventTarget?.matches(item.exclude)) return
                const matchedElement = eventTarget?.closest<Element>(
                    item.selector
                )
                if (!matchedElement) return
                target = matchedElement
            } else target = eventCurrentTarget

            // eslint-disable-next-line max-len
            const enabledBySessionFraction = this._baseEndpointEnabledDetails().base_endpoint_enabled_by_session_fraction

            const payload: TrackerEvent = this._eventFactory.createActionEvent(
                target,
                item,
                windowEnv,
                {
                    attachDomContext:
                        event.type === "click" &&
                        this._isEventMarkingEnabled(
                            this.config.markEventFraction
                        ),
                    domContext: {
                        parentsCountLimit: 2,
                        nestingLevelLimit: 50,
                        elementNodesLimit: 100,
                    },
                    isIFrame: !isTopWindow(),
                    parentWindowSessionId: this._parentWindowSessionId || undefined,
                    parentWindowUserId: this._parentWindowUserId || undefined,
                    parentWindowNotResponding: this._parentWindowNotResponding !== null
                        ? this._boolToNumber(this._parentWindowNotResponding)
                        : undefined,
                    // eslint-disable-next-line max-len
                    baseEndpointEnabledByReteFraction: this._baseEndpointEnabledDetails().base_endpoint_enabled_by_rete_fraction,
                    // eslint-disable-next-line max-len
                    baseEndpointEnabledByGaFraction: this._baseEndpointEnabledDetails().base_endpoint_enabled_by_ga_fraction,
                    // eslint-disable-next-line max-len
                    baseEndpointEnabledByYmFraction: this._baseEndpointEnabledDetails().base_endpoint_enabled_by_ym_fraction,
                    // eslint-disable-next-line max-len
                    baseEndpointEnabledByTrigger: this._baseEndpointEnabledDetails().base_endpoint_enabled_by_trigger,
                    // eslint-disable-next-line max-len
                    baseEndpointForceEnabled: this._baseEndpointEnabledDetails().base_endpoint_enabled_by_force,
                    baseEndpointEnabledBySessionFraction: enabledBySessionFraction,
                    baseEndpointEnabledByUrlParameter: (
                        this._baseEndpointEnabledDetails().base_endpoint_enabled_by_url_parameter
                    ),
                }
            )

            if (item.handler)
                this._sendEvent(
                    item.handler(event, target, item, payload),
                    target
                )
            else this._sendEvent(payload, target)
            ;(function(e: any): void {
                e["reteWasProcessed"] = true
            })(event)
        } catch (error) {
            if (this._debugMode) this.log(error)
        }
    }

    protected _handleIntersect(entries: any[]): void {
        entries.forEach(entry => {
            if (!entry.isIntersecting) return
            if (!this._intersectionObserver) return

            const element = entry.target as Element
            this._intersectionObserver.unobserve(element)

            const selector_hash = element.getAttribute("reteOnVisibleSelector")
            if (!selector_hash) return

            const item = this._selectorToEventConfig[
                selector_hash
            ] as EventConfig
            this._sendEvent(
                this._eventFactory.createActionEvent(element, item, window, {
                    isIFrame: !isTopWindow(),
                    parentWindowSessionId: this._parentWindowSessionId || undefined,
                    parentWindowUserId: this._parentWindowUserId || undefined,
                    parentWindowNotResponding: this._parentWindowNotResponding !== null
                        ? this._boolToNumber(this._parentWindowNotResponding)
                        : undefined,
                    // eslint-disable-next-line max-len
                    baseEndpointEnabledByReteFraction: this._baseEndpointEnabledDetails().base_endpoint_enabled_by_rete_fraction,
                    // eslint-disable-next-line max-len
                    baseEndpointEnabledByGaFraction: this._baseEndpointEnabledDetails().base_endpoint_enabled_by_ga_fraction,
                    // eslint-disable-next-line max-len
                    baseEndpointEnabledByYmFraction: this._baseEndpointEnabledDetails().base_endpoint_enabled_by_ym_fraction,
                    // eslint-disable-next-line max-len
                    baseEndpointEnabledByTrigger: this._baseEndpointEnabledDetails().base_endpoint_enabled_by_trigger,
                    // eslint-disable-next-line max-len
                    baseEndpointForceEnabled: this._baseEndpointEnabledDetails().base_endpoint_enabled_by_force,
                    baseEndpointEnabledByUrlParameter: (
                        this._baseEndpointEnabledDetails().base_endpoint_enabled_by_url_parameter
                    ),
                }),
                element
            )
        })
    }

    protected _baseEndpointEnabledDetails(): EndpointEnabledDetails {
        if (!this.config.baseEndpointFraction) {
            return {
                base_endpoint_enabled_by_rete_fraction: 1,
                base_endpoint_enabled_by_ga_fraction: 0,
                base_endpoint_enabled_by_ym_fraction: 0,
                base_endpoint_enabled_by_session_fraction: 0,
                base_endpoint_enabled_by_trigger: this._baseEndpointEnabledByTrigger()
                    ? 1
                    : 0,
                base_endpoint_enabled_by_force: forceTrackingEnabled() ? 1 : 0,
                base_endpoint_enabled_by_url_parameter: this._urlTrigger.baseEndpointEnabled()
                    ? 1 : 0,
            }
        }

        return {
            base_endpoint_enabled_by_rete_fraction: getEnabledAfterDownsampling(
                this.userId,
                this.config.baseEndpointFraction.rete,
            ) ? 1 : 0,
            base_endpoint_enabled_by_ga_fraction: getEnabledAfterDownsampling(
                this.ga,
                this.config.baseEndpointFraction.ga,
            ) ? 1 : 0,
            base_endpoint_enabled_by_ym_fraction: getEnabledAfterDownsampling(
                this.ym_uid,
                this.config.baseEndpointFraction.ya,
            ) ? 1 : 0,
            base_endpoint_enabled_by_session_fraction: 0,
            base_endpoint_enabled_by_trigger: this._baseEndpointEnabledByTrigger()
                ? 1
                : 0,
            base_endpoint_enabled_by_force: forceTrackingEnabled() ? 1 : 0,
            base_endpoint_enabled_by_url_parameter: this._urlTrigger.baseEndpointEnabled()
                ? 1 : 0,
        }
    }


    protected _baseEndpointEnabledByTrigger() {
        return Boolean(
            this._baseEndpointTrigger && this._baseEndpointTrigger.triggered
        )
    }

    protected _isEventSendingEnabled({
        fraction,
        eventFraction,
        trigger,
        endpointName,
        isInitialEvent,
        forceInitialEventsSending,
    }: EventSendingCheckerParams) {
        const enabledByUrlTrigger = endpointName
            ? this._urlTrigger.endpointEnabled(endpointName)
            : this._urlTrigger.baseEndpointEnabled()

        let enabledByEventFraction = false

        if (eventFraction) {
            enabledByEventFraction =  (
                getEnabledAfterDownsampling(this.userId, eventFraction.rete) ||
                getEnabledAfterDownsampling(this.ym_uid, eventFraction.ya) ||
                getEnabledAfterDownsampling(this.ga, eventFraction.ga)
            )
        }

        return (
            enabledByEventFraction ||
            this._forceTracking ||
            (trigger && trigger.triggered) ||
            enabledByUrlTrigger ||
            (isInitialEvent && forceInitialEventsSending) ||
            !fraction ||
            getEnabledAfterDownsampling(this.userId, fraction.rete) ||
            getEnabledAfterDownsampling(this.ym_uid, fraction.ya) ||
            getEnabledAfterDownsampling(this.ga, fraction.ga)
        )
    }

    protected _isSessionEventsSendingEnabled() {
        if (!this.config.sessionEventsFraction) return false
        return (
            getEnabledAfterDownsampling(this.userId, this.config.sessionEventsFraction.rete) ||
            getEnabledAfterDownsampling(this.userId, this.config.sessionEventsFraction.ga) ||
            getEnabledAfterDownsampling(this.userId, this.config.sessionEventsFraction.ya)
        )
    }

    protected _isEventMarkingEnabled(fraction: number | null) {
        if (!fraction) {
            return false
        }

        return (
            this._forceEventMarking ||
            getEnabledAfterDownsampling(this.userId, fraction) ||
            getEnabledAfterDownsampling(this.ym_uid, fraction) ||
            getEnabledAfterDownsampling(this.ga, fraction)
        )
    }

    protected _dispatchEventToPlugins(event: TrackerEvent) {
        for (const plugin of this._plugins) {
            try {
                if (plugin.onEvent) {
                    plugin.onEvent(event)
                }
            } catch {}
        }
    }

    protected _dispatchEventToTriggers(event: TrackerEvent) {
        if (this._baseEndpointTrigger) {
            this._baseEndpointTrigger.test(event.event_name)
        }

        for (const endpoint of this._endpoints) {
            if (!endpoint.trigger) continue
            endpoint.trigger.test(event.event_name)
        }
    }

    protected _addCookies(event: TrackerEvent): TrackerEvent {
        const cookies: { [key: string]: string } = {}
        let hasCookies = false
        this._config.collectCookies.forEach((cookieName) => {
            const value = getCookie(cookieName)
            if (typeof value === "string") {
                hasCookies = true
                cookies[cookieName] = value
            }
        })

        if (hasCookies) {
            event.cookies = JSON.stringify(cookies)
        }

        return event
    }

    protected _sendWindowUnloadEvent(event: TrackerEvent): void {
        this._dispatchEventToTriggers(event)
        this._dispatchEventToPlugins(event)
        this._eventsStatsManager.increment(event)
        event.setIndex(this._eventsStatsManager.getCurrentUserEventCount())
        event.setSessionIndex(
            this._eventsStatsManager.getCurrentSessionEventCount()
        )

        this._addCookies(event)

        if (this._debugMode) {
            this.log("Rete: event", JSON.stringify(event, null, 2))
            return
        }

        if (navigator) {
            const isSendingEnabled = this._isEventSendingEnabled({
                fraction: this.config.baseEndpointFraction,
                trigger: this._baseEndpointTrigger,
            })

            if (isSendingEnabled) {
                navigator.sendBeacon(joinURL(this._url, "unload"), `${event}`)
            }

            for (const endpoint of this._endpoints) {
                const isSendingEnabled = this._isEventSendingEnabled({
                    fraction: endpoint.fraction,
                    trigger: endpoint.trigger,
                    endpointName: endpoint.name,
                })
                if (isSendingEnabled) {
                    if (endpoint.unloadEventURL) {
                        navigator.sendBeacon(
                            joinURL(endpoint.baseURL, endpoint.unloadEventURL),
                            `${event}`
                        )
                    }
                }
            }
        }
    }

    protected _bindWindowUnloadEvent(): void {
        this._addEventListener(
            window,
            "unload",
            () => {
                this._sendWindowUnloadEvent(
                    this._createSimpleEvent("window_unloaded")
                )
            },
            false
        )
    }

    protected _sendEventToEndpoint({
        event,
        endpointName,
        baseURL,
        getEndpoint,
        postEndpoint,
    }: SendEventParams): void {
        if (getEndpoint) {
            const img = new Image()
            img.src = `${baseURL}/${getEndpoint}?${event.endpointView(endpointName)}`
            //TODO crunch for img livetime prolongation ? does it needed ?
            this._pixelRequestsQueue.push(img)
            if (this._pixelRequestsQueue.length > 20) {
                this._pixelRequestsQueue.shift()
            }
        }
        if (postEndpoint) {
            try {
                const request = new Request({ baseURL })
                request.post(postEndpoint, {
                    json: event.endpointView(endpointName),
                }).catch(this.log)
            } catch (err) {}
        }
    }

    /**
     * Send event data to API
     * @protected
     * @param  {TrackerEvent} event
     * @param {EventTarget} target
     * @return {void}
     */
    protected _sendEvent(event: TrackerEvent, target: EventTarget, forceSending = false): void {
        this._dispatchEventToTriggers(event)
        this._dispatchEventToPlugins(event)
        this._eventsStatsManager.increment(event)
        event.setIndex(this._eventsStatsManager.getCurrentUserEventCount())
        event.setSessionIndex(
            this._eventsStatsManager.getCurrentSessionEventCount()
        )

        this._addCookies(event)

        this._subscribers.forEach(listener => listener(event, target))

        if (this._debugMode) {
            this.log("Rete: event", JSON.stringify(event, null, 2))
            return
        }

        if (!this._yaSync && this._config.yaCounter) {
            const yacid = `yaCounter${this.config.yaCounter}`
            if (window[yacid]) {
                window[yacid].params({ reuser: getCookie("reuserid") })
                this._yaSync = true
                this.log(`Rete: ${yacid} ok`)
            }
        }

        const sendEventOnBaseEndpointEnabled = this._isEventSendingEnabled({
            fraction: this.config.baseEndpointFraction,
            eventFraction: event.fraction,
            trigger: this._baseEndpointTrigger,
            isInitialEvent: isInitialEvent(event),
            forceInitialEventsSending: this.config.forceInitialEventsSending,
        })


        if (sendEventOnBaseEndpointEnabled || forceSending) {
            if (event.fraction) {
                const eventFraction = event.fraction
                const enabledByEventFraction = (
                    getEnabledAfterDownsampling(this.userId, eventFraction.rete) ||
                    getEnabledAfterDownsampling(this.ym_uid, eventFraction.ya) ||
                    getEnabledAfterDownsampling(this.ga, eventFraction.ga)
                )
                event.base_endpoint_enabled_by_specific_event_fraction = this._boolToNumber(
                    enabledByEventFraction
                )
            }

            this._sendEventToEndpoint({
                event,
                baseURL: this._url,
                postEndpoint: "/event",
            })
        }

        for (const endpoint of this._endpoints) {
            const isSendingEnabled = this._isEventSendingEnabled({
                endpointName: endpoint.name,
                fraction: endpoint.fraction,
                trigger: endpoint.trigger,
                isInitialEvent: isInitialEvent(event),
                forceInitialEventsSending: Boolean(
                    endpoint.forceInitialEventsSending
                ),
            })
            if (isSendingEnabled || forceSending) {
                this._sendEventToEndpoint({
                    event,
                    endpointName: endpoint.name,
                    baseURL: endpoint.baseURL,
                    getEndpoint: endpoint.getURL,
                    postEndpoint: endpoint.postURL,
                })
            }
        }
    }

    public registerResourceError(err: ErrorEvent): void {
        this._errorTracker.registerRawError(err)
    }

    public injectDomObservers(domObservers: DomCollectorTarget[]): void {
        this._bindDomObservers(domObservers)
    }

    public injectDebugCollection(collection: EventCollection) {
        injectDebugCollection(collection)
    }

    public removeDebugCollection() {
        removeDebugCollection()
    }

    protected _bindSwipeTracker({ collections }: SwipeTrackerConfig): void {
        if (!collections.length) {
            return
        }

        // TODO: убрать try/catch в будущем
        try {
            const onSwipe = createSwipeTracker({ collections })
            onSwipe.subscribe((swipeEvent) => {
                this._sendEvent(
                    this._eventFactory.createEvent({
                        event_name: swipeEvent.target.eventName,
                        event_custom_name: swipeEvent.target.eventCustomName,
                        source_selector: swipeEvent.target.selector,
                        event_value: swipeEvent.direction,
                        event_trigger: this._eventFactory.getEventTrigger(
                            swipeEvent.eventTarget, window
                        ),
                        extensive_event_trigger: this._eventFactory.getExtensiveEventTrigger(
                            swipeEvent.eventTarget
                        ),
                        is_iframe: isTopWindow() ? 0 : 1,
                        parent_window_session_id: this._parentWindowSessionId || undefined,
                        parent_window_user_id: this._parentWindowUserId || undefined,
                        parent_window_not_responding: this._parentWindowNotResponding !== null
                            ? this._boolToNumber(this._parentWindowNotResponding)
                            : undefined,
                        ...this._baseEndpointEnabledDetails(),
                    }, undefined, undefined, true),
                    window,
                    false,
                )
            })
        } catch (err) {
            this.log(err)
        }
    }

    /**
     *
     * @param domObservers = dom observer collection
     */
    protected _bindDomObservers(domObservers: DomCollectorTarget[]): void {
        const last = this._lastObserversResults
        const serializeParseResult = (collect: any) => {
            const isObject = typeof collect === "object"
            if (!isObject) return String(collect)
            return JSON.stringify(collect)
        }
        createDomCollector({
            targets: domObservers,
            rootEl: document.body,
            onCollect: ({ name, parsedContent, payload }) => {
                if (payload && payload.doNotSendEmptyResults && parsedContent === null) {
                    return
                }

                if (
                    payload &&
                    (typeof payload.pageFilter === "string" ||
                        payload.pageFilter instanceof RegExp ||
                        typeof payload.pageFilter === "function")
                ) {
                    if (!applyPageFilter(payload.pageFilter)) {
                        return
                    }
                }

                let fraction: Fraction | undefined

                if (payload && typeof payload.fraction === "object") {
                    if (typeof payload.fraction.rete === "number" &&
                        typeof payload.fraction.ga === "number" &&
                        typeof payload.fraction.ya === "number") {
                        fraction = payload.fraction
                    }
                }

                const serializedContent = serializeParseResult(parsedContent)
                
                const disableDuplicateChecking = Boolean(
                    typeof payload === "object" && payload.disableDuplicateChecking
                )

                if (disableDuplicateChecking
                    || (last[name] !== serializedContent)) {
                    this.dispatchCustomEvent({
                        event_name: name,
                        dom_info: JSON.stringify(parsedContent),
                        fraction,
                    })
                    last[name] = serializedContent
                }
            },
        })
    }

    protected _eventsConfigsInspectorEnabled() {
        if (getCookie("rete-force-config-inspector") === "true") {
            return true
        }

        if (!this.config.inspectConfigurationFraction) {
            return true
        }

        return getEnabledAfterDownsampling(
            this.userId,
            this.config.inspectConfigurationFraction.rete
        )
    }

    protected _getRemoteConfig() {
        if (typeof this.config.remoteConfigSiteId === "number") {
            return getRemoteConfig(this.config.remoteConfigSiteId, this._analyticsToolsApiUrl)
                .then((collections) => {
                    if (collections.length) {
                        this.config.collections.unshift(...collections)
                    }
                })
        }
        return Promise.resolve()
    }

    protected _bindEventsConfigsInspector() {
        if (!this._eventsConfigsInspectorEnabled()) {
            return
        }

        const { foundConfigs } = createEventsConfigsInspector({
            collections: this.config.collections,
        })

        createTransportWorker({
            foundConfigs,
            worker: (foundConfigs) => {
                const targetConfigs = foundConfigs
                    .filter((config) => !config.sent)

                const payload = targetConfigs
                    .map((config) => ({
                        id: config.id,
                        selector: config.eventConfig.selector,
                        event_name: config.eventConfig.name,
                        event_custom_name: config.eventConfig.eventCustomName,
                        source: this.config.source,
                    }))

                if (!payload.length) {
                    return Promise.resolve([])
                }

                const request = new Request({
                    baseURL: this._configInspectorBaseUrl,
                })

                return request.post("/", {
                    json: payload
                }).then(() => targetConfigs.map(({ id }) => id)).catch((err) => {
                    this.log(err)
                    return []
                })
            },
        })
    }

    /**
     * Binds events
     * @protected
     * @param {EventCollection[]} collections
     */
    protected _bindEvents(collections: EventCollection[]): void {
        collections.forEach(collection => {
            const {
                iframe,
                rootSelector,
                dynamicallyInjectedIframe,
                events,
            } = collection

            const bindEventCollection = (iframe?: HTMLIFrameElement): void => {
                try {
                    const documentElement = iframe ? iframe.contentDocument : document
                    const windowEnv = iframe ? iframe.contentWindow : window
    
                    if (!documentElement || !windowEnv) {
                        return
                    }

                    if (iframe) {
                        const alreadyObserved = this._observedIframes.some((f) => {
                            return f.element === iframe && f.collection === collection
                        })
    
                        if (alreadyObserved) return
    
                        this._observedIframes.push({
                            element: iframe,
                            collection,
                        })
                    }

    
                    for (const item of events)
                        this._bindEvent(item, documentElement, windowEnv, rootSelector)
                } catch (err) {
                    this.log(err)
                }
            }

            const bindStaticIframes = () => {
                if (!iframe) return
                const iframes = document.querySelectorAll(iframe)
                for (const iframe of iframes) {
                    if (iframe instanceof HTMLIFrameElement) {
                        bindEventCollection(iframe)
                    }
                }
            }

            if (iframe && dynamicallyInjectedIframe) {
                bindStaticIframes()
                const mutationObserver = new MutationObserver(mutations => {
                    for (const mutation of mutations) {
                        for (const node of mutation.addedNodes) {
                            if (!(node instanceof Element)) continue
                            if (node.matches(iframe) && node instanceof HTMLIFrameElement) {
                                bindEventCollection(node)
                                continue
                            }
                            const nestedIframes = node.querySelectorAll(iframe)
                            for (const iframe of nestedIframes) {
                                if (!(iframe instanceof HTMLIFrameElement)) continue
                                bindEventCollection(iframe)
                                continue
                            }
                        }
                    }
                })
                const options: MutationObserverInit = {
                    childList: true,
                    subtree: true,
                }
                mutationObserver.observe(document.body, options)
            } else if (iframe) {
                bindStaticIframes()
            } else {
                bindEventCollection()
            }
        })
    }

    /**
     * Set handler for element specified in config
     * @protected
     * @param {EventConfig} item
     * @param {Document} documentElement - target document
     * @param {Window} windowEnv - target window 
     * @param {string?} rootSelector - selector for element listeners will be
     * attached to
     */
    protected _bindEvent(
        item: EventConfig,
        documentElement: Document,
        windowEnv: Window,
        rootSelector?: Selector,
    ): void {
        if (!item.selector) return
        const eventType = item.eventType || "click"
        try {
            const useCapture = true
            if (rootSelector) {
                // DYNAMIC EVENTS
                const roots = documentElement.querySelectorAll<HTMLElement>(
                    rootSelector
                )
                for (const root of roots) {
                    this._addEventListener(
                        root,
                        eventType,
                        (event: Event) => {
                            // filter dispatched by scripts events
                            if (event.isTrusted === false) {
                                return
                            }

                            this._handleEvent(
                                event,
                                item,
                                true,
                                windowEnv as Window
                            )
                        },
                        useCapture
                    )
                }
            } else {
                // STATIC EVENTS
                const elements = documentElement.querySelectorAll(
                    item.selector.replace(/:.+/g, "")
                )
                elements.forEach(elem => {
                    this._addEventListener(
                        elem,
                        eventType,
                        (event: Event) => {
                            this._handleEvent(
                                event,
                                item,
                                false,
                                windowEnv as Window
                            )
                        },
                        useCapture
                    )
                })
            }
        } catch (e) {
            if (this._debugMode) this.log(e, item, rootSelector)
        }
    }

    protected _bindOnVisibleEvents(configs: EventConfig[]): void {
        if (!configs) return

        //TODO цепляем IntersectionObserver только к уже существующим элементам,
        // новые динамически созданные элементы наблюдаться не будут
        configs.forEach(config => {
            const selector = config.selector.replace(/:.+/g, "")
            const selector_hash = md5(selector)
            document.querySelectorAll(selector).forEach(element => {
                if (!this._intersectionObserver) return
                element.setAttribute("reteOnVisibleSelector", selector_hash)
                this._selectorToEventConfig[selector_hash] = config as any
                this._intersectionObserver.observe(element)
            })
        })
    }
}
