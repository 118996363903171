import { parseDOM } from "@retentioneering/retentioneering-dom-observer"
import {
    TrackerEvent,
    EventPayload,
} from "./Event"
import {
    EventConfig,
    Config,
    BaseEndpointOptions,
    EndpointOptions,
    DataAttrsWhiteList,
} from "./Config"
import { instanceOf, isStrangeElement } from "./utils"
import { getExternalReferrer } from "./UserSession"
import { getDomContext } from "./lib/dom-context"
import { isFeatureEnabled } from "./Fraction"

enum ElementType {
    LINK = "link",
    BUTTON = "button",
    TEXT = "text",
    CHECKBOX = "checkbox",
    RADIO = "radio",
    NUMBER = "number",
    SELECT = "select",
    FORM = "form",
    OTHER = "other",
}

/**
 * Window environment passed to the functions
 *
 * If event happens in a nested iframe which has it's own window object with
 * it's own element constructors.
 * We need them to test if event target element is an instance of a certain
 * element type (i.g. HTMLInputElement) since
 * instanceof operator tests constructors for reference equality and
 * HTMLInputElement of the top level window !== HTMLInputElement
 * of a nested iframe.
 */

/**
 * Classifies element that triggered event
 * @param  {EventTarget} element
 * @param  {Window} windowEnv
 * @return {String} element type
 */
function getElementType(element: Element, windowEnv: Window): ElementType {
    if (element.tagName === "A") return ElementType.LINK
    if (element.tagName === "BUTTON") return ElementType.BUTTON
    if (element.tagName === "SELECT") return ElementType.SELECT
    if (element.tagName === "FORM") return ElementType.FORM
    if (element.tagName === "TEXTAREA") return ElementType.TEXT

    if (instanceOf<HTMLInputElement>(element, windowEnv.HTMLInputElement)) {
        if (element.type === "checkbox") return ElementType.CHECKBOX
        if (element.type === "radio") return ElementType.RADIO
        if (element.type === "number") return ElementType.NUMBER
        return ElementType.TEXT
    }
    return ElementType.OTHER
}

/**
 * Creates event name based on its params
 * @param {EventTarget} element
 * @param  {Object} item
 * @param  {Window} windowEnv window object event target belongs to
 * @return {String} event name
 */
function getEventName(
    element: Element,
    item: EventConfig,
    windowEnv: Window
): string {
    if (item.name) return item.name
    let name = item.selector.replace(/[^a-zA-Z_-]/g, "")
    if (instanceOf<HTMLInputElement>(element, windowEnv.HTMLInputElement))
        name = element.type
    return `${name}_${item.eventType || "click"}`
}

interface Named {
    name: string
}

function hasName(element: any): element is Named {
    return "name" in element && element.name
}

/**
 * Get selector for XPath
 * @param {Element} element
 * @param {Window} windowEnv window object event target belongs to
 * @return {String} XPath selector
 */
function getXpathPart(element: Element, windowEnv: Window): string {
    let selector = element.tagName.toLowerCase()
    if (element.id) selector += `#${element.id}`
    if (element.className.trim()) {
        selector += `.${element.className
            .trim()
            .split(/\s+/)
            .join(".")}`
    }
    if (instanceOf<HTMLInputElement>(element, windowEnv.HTMLInputElement))
        selector += `[type="${element.type}"]`
    if (hasName(element)) selector += `[name="${element.name}"]`
    return selector
}

/**
 * Collect XPath for the whole parent tree
 * @param {Element} element
 * @param  {Window} windowEnv
 * @return {String} XPath
 */
function getXpath(element: Element, windowEnv: Window): string {
    const xpath = []
    let current: Element | null = element
    while (current && current !== document.body) {
        xpath.unshift(getXpathPart(current, windowEnv))
        current = current.parentElement
    }
    return xpath.join(" > ")
}

/**
 * Tries to get any kind of trigger identification
 * @param {Element} element
 * @param windowEnv
 * @return {String} event trigger name
 */
export function getEventTrigger(element: Element, windowEnv: Window) {
    const xpath = getXpath(element, windowEnv)
    let brothers: NodeListOf<HTMLElement>
    try {
        brothers = windowEnv.document.querySelectorAll<HTMLElement>(xpath)
    } catch (err) {
        return
    }

    if (brothers.length < 2) {
        // use original xpath
        return xpath
    } else {
        // add element number, e.g. | [2/3] means "3 elements on page, this is
        // the 2nd"
        let index = null
        for (let i = 0; i < brothers.length; i++)
            if (brothers[i] === element) index = i

        return index ? `${xpath} | [${index + 1}/${brothers.length}]` : xpath
    }
}

function getParents(element: Element, parents: Element[] = []): Element[] {
    parents.unshift(element)

    if (element.tagName === "HTML") {
        return parents
    }

    const parent = element.parentElement

    if (parent) {
        return getParents(parent, parents)
    }

    return parents
}

export function getExtensiveEventTrigger(element: Element) {
    const selectors = getParents(element).map(element => {
        if (!element.classList.length) {
            return element.tagName.toLocaleLowerCase()
        }

        return `.${element.className
            .trim()
            .split(/\s+/)
            .join(".")}`
    })

    return selectors.join(" > ")
}

type GetDomInfoParams = {
    domInfoConfig: EventConfig["domInfo"]
    targetElement: Element
}

function getDomInfo({ domInfoConfig, targetElement }: GetDomInfoParams) {
    if (!domInfoConfig) return null

    const rootElement: HTMLElement | null = domInfoConfig.closestSelector
        ? targetElement.closest(domInfoConfig.closestSelector)
        : document.body

    if (!rootElement) {
        return null
    }

    if (!(rootElement instanceof HTMLElement)) {
        return null
    }

    return parseDOM(domInfoConfig.parseConfig, rootElement)
}

type GetDataAttrsWhiteListParams = {
    node: Node
    whiteList: DataAttrsWhiteList
    parentDataAttrs: DOMStringMap[] 
}
function getDataAttrs({ node, whiteList, parentDataAttrs }: GetDataAttrsWhiteListParams) {
    if ((node instanceof HTMLElement)) {
        const dataset = node.dataset
        const names = dataset ? Object.getOwnPropertyNames(dataset) : []
        if (names.length) {
            const dataAttrsResult: DOMStringMap = {}
    
            let hasValidAttrs = false
        
            for (const name of names) {
                for (const filter of whiteList) {
                    if (typeof filter === "string" && name === filter) {
                        dataAttrsResult[name] = dataset[name]
                        hasValidAttrs = true
                    }
        
                    if (typeof filter === "function" && filter(name)) {
                        dataAttrsResult[name] = dataset[name]
                        hasValidAttrs = true
                    }
        
                    if (filter instanceof RegExp && filter.test(name)) {
                        dataAttrsResult[name] = dataset[name]
                        hasValidAttrs = true
                    }
                }
            }

            if (hasValidAttrs) {
                parentDataAttrs.push(dataAttrsResult)
            }
        }
    }

    if (node.parentNode) {
        getDataAttrs({ node: node.parentNode, whiteList, parentDataAttrs })
    }

    return parentDataAttrs
}

type GetParentsDataAttrs = {
    node: Node
    whiteList: DataAttrsWhiteList | null
}

function getParentsDataAttrs({ node, whiteList }: GetParentsDataAttrs) {
    if (!whiteList || !whiteList.length) return []

    const parentDataAttrs: DOMStringMap[] = []

    getDataAttrs({
        node,
        whiteList,
        parentDataAttrs,
    })

    return parentDataAttrs
}

export type CustomizablePayload = {
    base_endpoint_options?: BaseEndpointOptions 
    additional_endpoints_options?: EndpointOptions[]
} & Pick<
    EventPayload,
    | "event_name"
    | "event_custom_name"
    | "event_value"
    | "event_trigger"
    | "extensive_event_trigger"
    | "dom_data_attrs"
    | "dom_context"
    | "collect_dom"
    | "event_auto"
    | "dom_info"
    | "params"
    | "next_location"
    | "dmp_id"
    | "dmp_response_ms"
    | "dmp_user_info"
    | "source_selector"
    | "parent_window_session_id"
    | "is_iframe"
    | "parent_window_user_id"
    | "parent_window_not_responding"
    | "base_endpoint_enabled_by_rete_fraction"
    | "base_endpoint_enabled_by_ga_fraction"
    | "base_endpoint_enabled_by_ym_fraction"
    | "base_endpoint_enabled_by_trigger"
    | "base_endpoint_enabled_by_force"
    | "base_endpoint_enabled_by_url_parameter"
    | "base_endpoint_enabled_by_session_fraction"
    | "base_endpoint_enabled_by_specific_event_fraction"
    | "matched_config_id"
    | "has_matched_config"
    | "fraction"
    | "fingerprint"
>

export type CreateActionEventOptions = {
    attachDomContext?: boolean
    domContext?: {
        parentsCountLimit?: number
        elementNodesLimit?: number
        textNodesLimit?: number
        nestingLevelLimit?: number
    }
    isIFrame?: boolean
    parentWindowSessionId?: string | null
    parentWindowUserId?: string
    parentWindowNotResponding?: 1 | 0
    baseEndpointEnabledByReteFraction?: 1 | 0
    baseEndpointEnabledByGaFraction?: 1 | 0
    baseEndpointEnabledByYmFraction?: 1 | 0
    baseEndpointEnabledByTrigger?: 1 | 0
    baseEndpointForceEnabled?: 1 | 0
    baseEndpointEnabledByUrlParameter?: 1 | 0
    baseEndpointEnabledBySessionFraction?: 1 | 0
    baseEndpointEnabledBySpecificEventFraction?: 1 | 0
}

export class EventFactory {
    protected _config: Config
    protected _version: string
    protected _getUserId: () => string
    protected _getSessionId: () => string
    protected _getGaId: () => (string | null)
    protected _getYmId: () => (string | null)

    constructor(
        config: Config,
        version: string,
        getUserId: () => string,
        getSessionId: () => string,
        getGaId: () => string | null,
        getYmId: () => string | null,
    ) {
        this._config = config
        this._version = version
        this._getUserId = getUserId
        this._getGaId = getGaId
        this._getYmId = getYmId
        this._getSessionId = getSessionId

        this.createActionEvent = this.createActionEvent.bind(this)
        this.createEvent = this.createEvent.bind(this)
        this._getLocationType = this._getLocationType.bind(this)
    }

    /**
     * Collects payload for event
     * @param {Element} element -  element event was dispatched on
     * @param {Object} item - config
     * @param windowEnv
     * @return {Event} tracker event
     */
    createActionEvent(
        element: Element,
        item: EventConfig,
        windowEnv: Window = window,
        options: CreateActionEventOptions = {}
    ): TrackerEvent {
        const event_name = getEventName(element, item, windowEnv)
        let event_value
        const event_trigger = getEventTrigger(element, windowEnv)
        const extensive_event_trigger = getExtensiveEventTrigger(element)

        const attachDomContext =
            Boolean(options.attachDomContext)

        const domContext = attachDomContext
            ? getDomContext(element, {
                parentsCountLimit:
                      (options.domContext &&
                          options.domContext.parentsCountLimit) ||
                      10000,
                elementNodesLimit:
                      options.domContext &&
                      options.domContext.elementNodesLimit,
                nestingLevelLimit:
                      options.domContext &&
                      options.domContext.nestingLevelLimit,
            })
            : undefined

        const domInfo = getDomInfo({
            domInfoConfig: item.domInfo,
            targetElement: element,
        })

        let next_location

        const elementType = getElementType(element, windowEnv)
        switch (elementType) {
            case ElementType.LINK: {
                event_value = (element as HTMLAnchorElement).innerText
                next_location = (element as HTMLAnchorElement).href
                break
            }
            case ElementType.BUTTON: {
                event_value = (element as HTMLButtonElement).innerText
                break
            }
            case ElementType.TEXT: {
                event_value = (element as HTMLInputElement).value
                break
            }
            case ElementType.CHECKBOX: {
                event_value = `${
                    (element as HTMLInputElement).checked ? "on" : "off"
                }:${element.parentElement?.innerText.trim()}`
                break
            }
            case ElementType.RADIO: {
                event_value = `${
                    (element as HTMLInputElement).value
                }:${element.parentElement?.innerText.trim()}`
                break
            }
            case ElementType.NUMBER:
            case ElementType.SELECT: {
                event_value = (element as HTMLInputElement).value
                break
            }
            case ElementType.FORM: {
                next_location = (element as HTMLFormElement).action
                break
            }
            case ElementType.OTHER: {
                event_value = (element as HTMLElement).innerText
                break
            }
        }

        if (item.textFrom) {
            const text = element.querySelector<HTMLElement>(item.textFrom)
                ?.innerText
            if (text) {
                event_value =
                    text === event_value ? text : `${text}|${event_value}`
            }
        }

        const dataset = element instanceof HTMLElement || isStrangeElement(element)
            ? (element as HTMLElement).dataset
            : null

        const hasDataAttrs = Boolean(
            dataset && Object.getOwnPropertyNames(dataset).length
        )


        let dataAttrs = hasDataAttrs && dataset ? [dataset] : []

        const collectDataAttrsEnabled = isFeatureEnabled({
            fraction: this._config.collectDataAttrsFraction,
            forceCookieName: "force-data-attrs-collection",
            userId: this._getUserId(),
            gaId: this._getGaId(),
            ymId: this._getYmId(),
        })

        if (collectDataAttrsEnabled && element.parentNode) {
            const parentDataAttrs = getParentsDataAttrs({
                node: element.parentNode,
                whiteList: this._config.dataAttrsWhiteList,
            })
            dataAttrs = dataAttrs.concat(parentDataAttrs)
        }


        const customName = dataset && dataset.reteEventCustomName 
            ? dataset.reteEventCustomName
            : item.eventCustomName


        return this.createEvent(
            {
                event_name: event_name,
                event_custom_name: customName,
                event_value: event_value,
                event_trigger: event_trigger,
                extensive_event_trigger,
                matched_config_id: item.id,
                dom_data_attrs: dataAttrs.length ? JSON.stringify(dataAttrs) : undefined,
                has_matched_config: typeof item.id === "number" ? 1 : 0,
                dom_info: domInfo ? JSON.stringify(domInfo) : undefined,
                dom_context: domContext
                    ? JSON.stringify(domContext)
                    : undefined,
                collect_dom: domContext ? 1 : undefined,
                event_auto: item.isDefault,
                source_selector: item.selector,
                is_iframe: options.isIFrame ? 1 : 0,
                parent_window_session_id: options.parentWindowSessionId || undefined,
                parent_window_user_id: options.parentWindowUserId || undefined,
                parent_window_not_responding: typeof options.parentWindowNotResponding === "number"
                    ? options.parentWindowNotResponding : undefined,
                base_endpoint_enabled_by_rete_fraction:
                    options.baseEndpointEnabledByReteFraction,
                base_endpoint_enabled_by_ga_fraction:
                    options.baseEndpointEnabledByGaFraction,
                base_endpoint_enabled_by_ym_fraction:
                    options.baseEndpointEnabledByYmFraction,
                base_endpoint_enabled_by_trigger:
                    options.baseEndpointEnabledByTrigger,
                base_endpoint_enabled_by_specific_event_fraction:
                    options.baseEndpointEnabledBySpecificEventFraction,
                base_endpoint_enabled_by_force: options.baseEndpointForceEnabled,
                base_endpoint_enabled_by_url_parameter: options.baseEndpointEnabledByUrlParameter,
                // eslint-disable-next-line max-len
                base_endpoint_enabled_by_session_fraction: options.baseEndpointEnabledBySessionFraction,
                fraction: item.fraction,
            },
            next_location,
            item,
        )
    }

    /**
     * Constructs event
     * @param  {object} payload
     * @param  {string} [nextLocation]
     * @return {Event} constructed event
     */
    createEvent(
        payload: CustomizablePayload,
        nextLocation?: string,
        item?: EventConfig,
        noHash = false,
    ): TrackerEvent {
        const sendValue =
            (item && item.sendValue) || this._config.defaultSendValue

        const event = new TrackerEvent({
            current_location: document.URL,
            current_type: this._getLocationType(document.location.pathname),
            next_location: nextLocation,
            next_type: nextLocation
                ? this._getLocationType(nextLocation)
                : undefined,
            version: this._version,
            source: this._config.source,
            user_id: this._getUserId(),
            external_referrer: getExternalReferrer() || undefined,
            client_session_id: this._getSessionId(),
            base_endpoint_options: payload.base_endpoint_options || {
                sendValue: sendValue || undefined,
            },
            additional_endpoints_options: payload.additional_endpoints_options || (item
                ? item.endpointsOptions
                : undefined),
            ...payload,
        }, noHash)
        if (this._config.modifyEvents) {
            this._config.modifyEvents(event)
        }
        if (typeof this._config.pageResolver === "function") {
            try {
                const pageName = this._config.pageResolver(event)
                event.page_name = pageName || event.page_name
            } catch (err) {
                return event
            }
        }
        return event
    }

    getEventTrigger(element: Element, windowEnv: Window) {
        return getEventTrigger(element, windowEnv)
    }

    getExtensiveEventTrigger(element: Element) {
        return getExtensiveEventTrigger(element)
    }

    /**
     * Detects which type (term in vocabulary) given page is
     * @param  {string} url
     * @return {string} screen
     */
    protected _getLocationType(url: string): string | undefined {
        if (!url) return

        const screens = this._config.screens
        if (!screens) return

        for (const screen in screens) {
            for (const pattern of screens[screen]) {
                if (url.match(pattern)) {
                    return screen
                }
            }
        }
    }
}
