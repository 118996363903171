type Filter = string

type LoadResError = {
  tagName?: string
  src?: string
  type?: string
}

type CreateErrorTrackerParams = {
  onError: (event: LoadResError) => void 
  filters?: Filter[]  
}


type FilterErrorsParams = {
  errors: ErrorEvent[]
  filters?: Filter[]
}


type FilterErrorParams = {
    error: ErrorEvent
    filters?: Filter[]
}

const filterError = (({ error, filters }: FilterErrorParams) => {
    if (!filters || !filters.length) {
        return true
    }
    if (error.target instanceof Element && error.target.tagName) {
        return !filters.includes(error.target.tagName.toLowerCase())
    }
    return true
})

const filterErrors = ({ errors, filters }: FilterErrorsParams) => {
    try {
        if (!filters || !filters.length) {
            return errors
        }

        return errors.filter((error) => filterError({ error, filters }))
    } catch (err) {
        return errors
    }
}

export type ErrorTracker = {
  collectErrors: () => void
  registerRawError: (err: ErrorEvent) => void
}

export const createErrorTracker = (p: CreateErrorTrackerParams): ErrorTracker => {
    const { onError, filters } = p

    const dispatchErr = (err: ErrorEvent): LoadResError => {
        let tagName: string | undefined = undefined
        let src: string | undefined = undefined
        let type: string | undefined = undefined

        if (err.target instanceof Element) {
            tagName = err.target.tagName
        }

        if (err.target instanceof HTMLScriptElement && err.target.src) {
            src = err.target.src
        }

        if (err.target instanceof HTMLLinkElement && err.target.href) {
            src = err.target.href
            type = err.target.type
        }
        
        return {
            tagName,
            src,
            type,
        }
    }

    const dispatchErrorsEvents = (errs: ErrorEvent[]) => {
        for (const err of errs) {
            onError(dispatchErr(err))
        }
    } 
  
    const collectErrors = () => {
        const errors = filterErrors({
            errors: window.reteLoadResErrors || [],
            filters,
        })

        if (errors.length) {
            dispatchErrorsEvents(errors)
        }
    }

    const registerRawError = (err: ErrorEvent) => {
        if (filterError(err)) {
            onError(dispatchErr(err))
        }
    }

    return {
        collectErrors,
        registerRawError,
    }
}
