import { getCookie } from "./cookie"
import { crc32 } from "./utils"

export type Fraction = {
  rete: number
  ga: number
  ya: number
}


export function getEnabledAfterDownsampling(
    id: string | null | undefined,
    activatedFraction: number
): boolean {
    if (!id) {
        return false
    }
    const discretizator = 524309
    const groupId = (crc32(id) >>> 3) % discretizator
    const groupIdThreshold = activatedFraction * discretizator
    return groupId < groupIdThreshold
}


type IsFeatureEnabledParams = {
  fraction?: Fraction | number | null
  forceCookieName: string
  userId: string
  gaId?: (string | null)
  ymId?: (string | null)
}

export function isFeatureEnabled({
    fraction,
    forceCookieName,
    userId,
    gaId,
    ymId
}: IsFeatureEnabledParams) {
    if (fraction === null || typeof fraction === "undefined") return true

    if (getCookie(forceCookieName) === "true") return true

    if (typeof fraction === "number") {
        return getEnabledAfterDownsampling(userId, fraction)
    }

    if (typeof fraction === "object") {
        return (
            getEnabledAfterDownsampling(userId, fraction.rete) ||
            getEnabledAfterDownsampling(gaId, fraction.ga) ||
            getEnabledAfterDownsampling(ymId, fraction.ya)
        )
    }

    return false
}
