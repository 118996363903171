import Observable from "zen-observable"

interface Emitter {
  subscribe: (handler: (event: any, target: any) => void) => void
  unsubscribe: (handler: (event: any, target: any) => void) => void
}

type SessionEvent = {
  name: "start_session"
  reason: "last_session_expired" | "external_referrer" | "first_session"
}

const LAST_EVENT_TIMESTAMP_KEY = "last_event_ts"
const LAST_EXTERNAL_REFERRER_KEY = "rete-last-external-referrer"
const DEFAULT_SESSION_LIFETIME = 1000 * 60 * 30
const CHECK_SESSION_INTERVAL = 10000

const updateLastEventMsTs = () => {
    return window.localStorage.setItem(LAST_EVENT_TIMESTAMP_KEY, `${+new Date()}`)
}

const getLastEventMsTs = () => {
    const strVal = window.localStorage.getItem(LAST_EVENT_TIMESTAMP_KEY)
    if (strVal === null) return null
    const ts = +strVal
    return ts
}

const updateLastExternalReferrer = (referrer: string) => {
    window.localStorage.setItem(LAST_EXTERNAL_REFERRER_KEY, referrer)
}

const getLastExternalReferrer = () => {
    return window.localStorage.getItem(LAST_EXTERNAL_REFERRER_KEY)
}

const sessionExpired = (userLifetime: number = DEFAULT_SESSION_LIFETIME) => {
    const lastEventMsTs = getLastEventMsTs()
    if (!lastEventMsTs) return false
    const deltaMs = (+new Date() - lastEventMsTs)
    return deltaMs > userLifetime
}

const firstSession = () => {
    const lastEventMsTs = getLastEventMsTs()
    return lastEventMsTs === null
}

const makeSessionEvent = (reason: SessionEvent["reason"]): SessionEvent => ({
    name: "start_session",
    reason,
})

const theReferrerHasTheSameOrigin = () => {
    if (!document.referrer) {
        return false
    }
    try {
        const referrer = new URL(document.referrer)
        return (referrer.origin === location.origin)
    } catch (invalidUrlError) {
        return true
    }
}


export const getExternalReferrer = () => {
    return !theReferrerHasTheSameOrigin() ? document.referrer : null
}


export const listenSessionEvents = (emitter: Emitter) => {
    return new Observable<SessionEvent>((observer) => {
        const externalReferrer = getExternalReferrer()
        const lastExternalReferrer = getLastExternalReferrer()

        const externalReffererChanged = (
            externalReferrer !== null && externalReferrer !== lastExternalReferrer
        )
    
        if (externalReffererChanged && externalReferrer !== null) {
            updateLastExternalReferrer(externalReferrer)
        }

        if (firstSession()) {
            updateLastEventMsTs()
            observer.next(makeSessionEvent("first_session"))
        } else if (externalReffererChanged) {
            updateLastEventMsTs()
            observer.next(makeSessionEvent("external_referrer"))
        } else if (sessionExpired()) {
            updateLastEventMsTs()
            observer.next(makeSessionEvent("last_session_expired"))
        }

        let timeout: ReturnType<typeof setTimeout>

        const checkSessionWorker = () => {
            if (sessionExpired()) {
                updateLastEventMsTs()
                observer.next(makeSessionEvent("last_session_expired"))
            }
            timeout = setTimeout(checkSessionWorker, CHECK_SESSION_INTERVAL)
        }

        checkSessionWorker()

        const onEvent = () => {
            updateLastEventMsTs()
        }

        emitter.subscribe(onEvent)

        return () => {
            emitter.unsubscribe(onEvent)
            clearTimeout(timeout)
        }
    })
}
