import Observable from "zen-observable"

type PageviewEvent = {
  name: "pageview"
  url: string
}

const CHECK_URL_INTERVAL_MS = 600

export const listenPageviewEvents = () => {
    let currentLocation = window.location.href

    return new Observable<PageviewEvent>((observer) => {
        const emitPageViewEvent = () => {
            observer.next({
                name: "pageview",
                url: currentLocation,
            })
        }

        emitPageViewEvent()

        let timeout: ReturnType<typeof setTimeout>

        const checkUrl = () => {
            const newLocation = window.location.href
            if (newLocation !== currentLocation) {
                currentLocation = newLocation
                emitPageViewEvent()
            }
            timeout = setTimeout(checkUrl, CHECK_URL_INTERVAL_MS)
        }

        checkUrl()

        return () => {
            clearTimeout(timeout)
        }
    })
}
