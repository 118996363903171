import { Request } from "./lib/Request"
import { EventCollection } from "./Config"

export type RemoteEventConfigPage = {
    name: string
    page_filter_contain: string | null
    page_filter_regexp: string | null
}

export type RemoteEventConfig = {
  id: number
  selector: string
  collection_id: number
  event_custom_name: string | null
  comment: string | null
  name: string | null
  event_type: string | null
  is_default: boolean | null
  send_value: "none" | "hashOfValue" | "plainValue" | null
  text_from: string | null
  exclude: string | null
  page: RemoteEventConfigPage | null
}

export type RemoteConfigResponse = {
  id: number
  target_site_id: number
  name: string
  event_configs: RemoteEventConfig[]
  iframe: string | null
  dynamically_injected_iframe: boolean | null
  root_selector: string | null
  comment: string | null
}

const getPageFilter = (page: RemoteEventConfigPage | null) => {
    if (!page) {
        return
    }

    if (typeof page.page_filter_contain === "string" && page.page_filter_contain) {
        return page.page_filter_contain
    }

    if (typeof page.page_filter_regexp === "string" && page.page_filter_regexp) {
        try {
            const regexp = new RegExp(page.page_filter_regexp, "g")
            return regexp
        } catch (err) {
            // TODO: backend alert
        }
    }
}

const mapCollection = (collection: RemoteConfigResponse): EventCollection  => ({
    iframe: collection.iframe || undefined,
    dynamicallyInjectedIframe: collection.dynamically_injected_iframe || undefined,
    rootSelector: collection.root_selector || undefined,
    comment: collection.comment || undefined,
    events: collection.event_configs.map((eventConfug) => {
        const pageFilter = getPageFilter(eventConfug.page)
        return {
            id: eventConfug.id,
            comment: eventConfug.comment || undefined,
            name: eventConfug.name || undefined,
            eventCustomName: eventConfug.event_custom_name || undefined,
            selector: eventConfug.selector || "",
            eventType: eventConfug.event_type || undefined,
            isDefault: eventConfug.is_default || undefined,
            sendValue: eventConfug.send_value || undefined,
            textFrom: eventConfug.text_from || undefined,
            exclude: eventConfug.exclude || undefined,
            pageFilter: pageFilter || undefined,
        } 
    }),
})

export const getRemoteConfig = (targetSiteId: number, analyticsToolsApiUrl: string): Promise<EventCollection[]> => {
    const request = new Request({
        baseURL: analyticsToolsApiUrl
    })

    return request.get(`/sites/${targetSiteId}/collections`)
        .then((response) => {
            const remoteCollecitons = response.data as RemoteConfigResponse[]
            return remoteCollecitons.map(mapCollection)
        })
        .catch(() => {
            return []
        })

}
