import { EventCollection, EventConfig } from "./Config"
import { uuid } from "./lib/uuid"


type FoundConfig = {
  id: string
  collection: EventCollection
  eventConfig: EventConfig
  sent: boolean
}

type SearchTargetCollection = {
  collection: EventCollection
  notFoundConfigs: EventConfig[]
}

type SearchTarget = {
  collections: SearchTargetCollection[]
}

const MUTATION_OBSERVER_CONFIG: MutationObserverInit = {
    childList: true,
    subtree: true,
    characterData: false,
    characterDataOldValue: false,
    attributes: true,
    attributeFilter: ["class", "id", "type"],
    attributeOldValue: false,
}

const BODY_OBSERVER_CONFIG: MutationObserverInit = {
    childList: true,
    subtree: true,
    characterData: false,
    characterDataOldValue: false,
    attributes: false,
    attributeOldValue: false,
}


const createFoundConfig = (collection: EventCollection, eventConfig: EventConfig): FoundConfig => {
    return {
        id: uuid(),
        collection,
        eventConfig,
        sent: false
    }
}

const createSearchTarget = (collections: EventCollection[]): SearchTarget => {
    return {
        collections: collections.map((collection) => ({
            collection,
            notFoundConfigs: [...collection.events],
        }))
    }
}

const getDocumentAndWindow = (collection: EventCollection) => {
    if (!collection.iframe) {
        return { document, window }
    }
    const iframe = document.querySelector<HTMLIFrameElement>(collection.iframe)
    if (iframe) {
        return {
            document: iframe.contentDocument,
            window: iframe.contentWindow as ((Window & typeof globalThis) | null),
        }
    }
}

const collectionFirstSearch = (
    targetCollection: SearchTargetCollection,
    foundConfigs: FoundConfig[]
) => {
    const { collection, notFoundConfigs } = targetCollection
    const {
        document: documentElement,
        window: windowObj
    } = getDocumentAndWindow(collection) || {}

    if (!documentElement || !windowObj) return

    const rootEls = documentElement.querySelectorAll(collection.rootSelector || "body")
    if (!rootEls.length) return

    for (const rootEl of rootEls) {
        for (let i = 0; i < notFoundConfigs.length; i++) {
            const eventConfig = notFoundConfigs[i]
            if (!rootEl.querySelector(eventConfig.selector)) {
                continue
            }

            foundConfigs.push(createFoundConfig(collection, eventConfig))
            notFoundConfigs.splice(i, 1)
            i--
        }
    }
}

const firstSearch = (searchTarget: SearchTarget, foundConfigs: FoundConfig[]) => {
    for (const collection of searchTarget.collections) {
        collectionFirstSearch(collection, foundConfigs)
    }
}

type MutateParams = {
  target: SearchTargetCollection
  foundConfigs: FoundConfig[]
  mutations: MutationRecord[]
}

const onMutate = ({ target, foundConfigs, mutations }: MutateParams) => {
    for (const mutation of mutations) {
        for (let i = 0; i < target.notFoundConfigs.length; i++) {
            const eventConfig = target.notFoundConfigs[i]

            if (mutation.type === "attributes") {
                if (!(mutation.target instanceof HTMLElement)) continue
                if (!mutation.target.matches(eventConfig.selector)) continue
            }

            if (mutation.type === "childList") {
                const found = [...mutation.addedNodes].some((node) => {
                    return (
                        node instanceof HTMLElement &&
                        (node.matches(eventConfig.selector)
                        || node.querySelector(eventConfig.selector))
                    )
                })
                if (!found) continue
            }
            
            foundConfigs.push(createFoundConfig(target.collection, eventConfig))
            target.notFoundConfigs.splice(i, 1)
            i--
        }
    }
}

const observe = (searchTarget: SearchTarget, foundConfigs: FoundConfig[]) => {
    for (const targetCollection of searchTarget.collections) {
        const { collection, notFoundConfigs } = targetCollection
        const handleCollection = () => {
            const {
                document: documentElement,
                window: windowObj
            } = getDocumentAndWindow(collection) || {}
    
            if (!documentElement || !windowObj) return
    
            const roots = documentElement.querySelectorAll(collection.rootSelector || "body")
    
            if (!roots.length) {
                return
            }
    
            for (const root of roots) {
                const observer = new windowObj.MutationObserver((mutations) => {
                    onMutate({
                        target: { collection, notFoundConfigs },
                        mutations,
                        foundConfigs,
                    })
                })
        
                observer.observe(root, MUTATION_OBSERVER_CONFIG)
            }
        }

        const iframe = collection.iframe && document.querySelector(collection.iframe)
        if (collection.dynamicallyInjectedIframe && !iframe && collection.iframe) {
            const observer = new window.MutationObserver((mutations) => {
                for (const mutation of mutations) {
                    for (const node of mutation.addedNodes) {
                        if (!(node instanceof HTMLElement)) continue
                        if (!collection.iframe) continue
                        if (!node.matches(collection.iframe)) continue
                        
                        collectionFirstSearch(targetCollection, foundConfigs)
                        handleCollection()
                        observer.disconnect()
                    }
                }

            })

            observer.observe(document.body, BODY_OBSERVER_CONFIG)
            continue
        }

        handleCollection()
    }
}

type CreateInspectorParams = {
  collections: EventCollection[]
}

export const createEventsConfigsInspector = ({ collections }: CreateInspectorParams) => {
    const searchTarget = createSearchTarget(collections.map((collection) => {
        return {
            ...collection,
            events: collection.events.filter(({ isDefault }) => !isDefault)
        }
    }))
    const foundConfigs: FoundConfig[] = []
    
    firstSearch(searchTarget, foundConfigs)
    observe(searchTarget, foundConfigs)

    return {
        foundConfigs,
    }
}

type CreateWorkerParams = {
  foundConfigs: FoundConfig[]
  worker: (foundConfigs: FoundConfig[]) => Promise<FoundConfig["id"][]>
  delayMs?: number
}

export const createTransportWorker = ({
    foundConfigs,
    worker,
    delayMs = 600
}: CreateWorkerParams) => {
    const onComplete = (ids: FoundConfig["id"][]) => {
        for (const foundConfig of foundConfigs) {
            if (foundConfig.sent) continue
            if (ids.includes(foundConfig.id)) {
                foundConfig.sent = true
            }
        }
    }

    const run = () => {
        setTimeout(() => {
            worker(foundConfigs).then((ids) => {
                onComplete(ids)
                run()
            }).catch(run)
        }, delayMs)
    }

    run()
}
