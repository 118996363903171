import { waitFor } from "./wait-for"
/**
 * Get cookie by name
 * @param  {String} name
 * @return {String|Null} cookie value
 */
export function getCookie(name: string): string | null {
    const matches = document.cookie.match(
        new RegExp(
            `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1")}=([^;]*)`
        )
    )
    return matches ? decodeURIComponent(matches[1]) : null
}

/**
 * Wait for cookies
 * @param  name
 * @param  attemptIntervalMs
 * @param  maxWaitingMs
 * @return Promise
 */
export function waitForCookie(
    name: string,
    attemptIntervalMs = 500,
    maxWaitingMs?: number
): Promise<string | null> {
    const checkCookie = () => getCookie(name) !== null
    return waitFor(checkCookie, attemptIntervalMs, maxWaitingMs).then(
        cookieExists => {
            if (cookieExists) {
                return getCookie(name)
            }
            return null
        }
    )
}

/**
 * Sets cookie
 * @param {String} name
 * @param {String} value
 */
export function writeCookie(name: string, value: string): void {
    const exp = new Date()
    exp.setFullYear(exp.getFullYear() + 1)
    document.cookie = `${name}=${value};expires=${exp.toUTCString()};path=/`
}

/**
 * Sets cookie with expiration date
 * @param {String} name
 * @param {String} value
 * @param {Date} expires
 */
export function writeCookieExpires(
    name: string,
    value: string,
    expires: Date
): void {
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`
}
