import { listenMessages, Payload } from "./lib/PostMessage"

const REQUEST_EVENT_TYPE = "rete-parent-window-session-id-request"
const RESPONSE_EVENT_TYPE = "rete-parent-window-session-id-response"

interface SessionIdRequest extends Payload {
    type: typeof REQUEST_EVENT_TYPE
}

interface SessionIdResponse extends Payload {
    type: typeof RESPONSE_EVENT_TYPE
    sessionId: string
    userId: string
}

type GetParentSessionIdParams = {
    attemptIntervalMs?: number
    timeoutMs?: number
}

const getOrigin = (url: string) => {
    if (window.URL) {
        return (new URL(url).origin)
    }

    const a = document.createElement("a")
    a.href = url
    return a.origin
}

const getParentWindowOrigin = () => {
    return getOrigin(document.referrer)
}

const makeRequest = (): SessionIdRequest => ({
    type: REQUEST_EVENT_TYPE,
})

type MakeResponseParams = {
    sessionId: string
    userId: string
}

const makeResponse = ({ sessionId, userId }: MakeResponseParams): SessionIdResponse => ({
    type: RESPONSE_EVENT_TYPE,
    sessionId,
    userId,
})

const sendRequest = () => {
    const parentOrigin = getParentWindowOrigin()
    if (window.parent) {
        window.parent.postMessage(makeRequest(), parentOrigin)
    }
}

export const isTopWindow = () => (window.self === window.top)


export const getParentWindowSessionId = (
    { 
        attemptIntervalMs,
        timeoutMs,
    }: GetParentSessionIdParams): Promise<MakeResponseParams> => {

    return new Promise((resolve, reject) => {
        let received = false

        const response = listenMessages<SessionIdResponse>({
            eventType: RESPONSE_EVENT_TYPE,
            once: true,
        })
    
        response.subscribe((e) => {
            received = true
            resolve({
                sessionId: e.data.sessionId,
                userId: e.data.userId,
            })
        })

        const startedAt = Date.now()

        const attempt = () => {

            if (received) {
                return
            }

            sendRequest()

            const expired = typeof timeoutMs !== "number"
                ? false
                : (Date.now() - startedAt) > timeoutMs 

            if (typeof attemptIntervalMs === "number" && !expired) {
                setTimeout(() => attempt(), attemptIntervalMs)
            }

            if (typeof attemptIntervalMs === "number" && expired) {
                reject(new Error("timeout"))
            }
        }
    
        attempt()
    })
}

type ListenGetRequestsParams = {
    sessionId: string
    userId: string
}

export const listenGetParentWindowRequests = ({ sessionId, userId }: ListenGetRequestsParams) => {
    const request = listenMessages<SessionIdRequest>({
        eventType: REQUEST_EVENT_TYPE,
    })

    request.subscribe((e) => {
        const origin = e.origin
        const reqWin = e.source

        if (window && typeof window.postMessage === "function") {
            (reqWin as Window).postMessage(makeResponse({ sessionId, userId }), origin)
        }
    })
}
