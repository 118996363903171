import { ClientConfig } from "../core/Config"
import { initReteTracker } from "../core"

const reteConfig: ClientConfig = {
    source: "game",
    baseEndpointFraction: {
        rete: 1.0,
        ya: 1.0,
        ga: 1.0,
    },
    markEventFraction: 0.01,
    remoteConfigSiteId: 5,
    onVisibleEvents: [
        {
            selector: "*",
            eventType: "click",
        },
    ],
}

initReteTracker({
    config: reteConfig,
    onInit: tracker => (window.reteTracker = tracker),
})
