import { parseQueryParams } from "./url"

type StringOrRegExp = string | RegExp

const LS_KEY = "rete-url-trigger-info"

type Query = {
    [key: string]: string | string[]
}

export type UrlTrigger = {
  name: string
  value: StringOrRegExp[]
}

type TriggerInfo = {
  baseEndpointEnabled: boolean
  enabledAdditionalEndpoints: string[]
}

const defaultTriggerInfo = (): TriggerInfo => ({
    baseEndpointEnabled: false,
    enabledAdditionalEndpoints: [],
})

const mergeTriggerInfo = (triggerInfo1: TriggerInfo, triggerInfo2: TriggerInfo) => {
    const merged: TriggerInfo = {
        baseEndpointEnabled: triggerInfo1.baseEndpointEnabled || triggerInfo2.baseEndpointEnabled,
        enabledAdditionalEndpoints: triggerInfo1.enabledAdditionalEndpoints,
    }

    triggerInfo2.enabledAdditionalEndpoints.forEach((endpointName) => {
        if (!merged.enabledAdditionalEndpoints.includes(endpointName)) {
            merged.enabledAdditionalEndpoints.push(endpointName)
        }
    })

    return merged
}

const resolveTriggers = (triggers: UrlTrigger[], query: Query) => {
    return triggers.some((trigger) => {
        if (!query.hasOwnProperty(trigger.name)) {
            return false
        }
    
        const parameterValue = query[trigger.name]
    
        const values = Array.isArray(parameterValue)
            ? parameterValue
            : [parameterValue]
    
        for (const value of values) {
            for (const pattern of trigger.value) {
                if (pattern instanceof RegExp && pattern.test(value)) {
                    return true  
                } 
                if (value === pattern) {
                    return true
                }
            }
        }
    
        return false
    })
}

const resolve = (params: CreateUrlTriggerParams): TriggerInfo => {
    const query = parseQueryParams(window.location.search)

    let enabledEndpoints: string[] = []

    if (params.additionalEndpointsTriggers) {
        enabledEndpoints = params.additionalEndpointsTriggers
            .filter(({ urlTrigger }) => resolveTriggers(urlTrigger, query))
            .map(({ name }) => name)
    }
    return {
        baseEndpointEnabled: params.baseEndpointTrigger
            ? resolveTriggers(params.baseEndpointTrigger, query)
            : false,
        enabledAdditionalEndpoints: enabledEndpoints,
    }
}

const readStoredTriggerInfo = () => {
    const stored = window.localStorage.getItem(LS_KEY)

    if (!stored) {
        return null
    }
    try {
        // read from JSON & validate
        const parsed = JSON.parse(stored) as TriggerInfo
        const baseEndpointIsOk = typeof parsed.baseEndpointEnabled === "boolean"
        const additionalEndpoints = parsed.enabledAdditionalEndpoints
        const additionalEnpdointsIsArray = Array.isArray(additionalEndpoints)
        const additionalEndpointsIsOs = additionalEnpdointsIsArray && additionalEndpoints.every(
            (endpointName: string) => typeof endpointName === "string"
        )
        const schemaIsOk = baseEndpointIsOk && additionalEndpointsIsOs
        return schemaIsOk ? parsed : null
    } catch (err) {
        return null
    }
}


const writeTriggerInfo = (triggerInfo: TriggerInfo) => {
    window.localStorage.setItem(LS_KEY, JSON.stringify(triggerInfo))
}


export type CreateUrlTriggerParams = {
  baseEndpointTrigger?: UrlTrigger[]
  additionalEndpointsTriggers?: {
    name: string
    urlTrigger: UrlTrigger[]
  }[]
}

export const createUrlTrigger = (params: CreateUrlTriggerParams) => {
    let triggerInfo: TriggerInfo = defaultTriggerInfo()

    return {
        init: () => {
            const stored = readStoredTriggerInfo()
            if (stored) {
                triggerInfo = mergeTriggerInfo(triggerInfo, stored)
            }
            triggerInfo = mergeTriggerInfo(triggerInfo, resolve(params))
        },
        store: () => {
            writeTriggerInfo(triggerInfo)
        },
        baseEndpointEnabled: () => triggerInfo.baseEndpointEnabled,
        endpointEnabled: (endpointName: string) => {
            return triggerInfo.enabledAdditionalEndpoints.includes(endpointName)
        }
    }
}

export type UrlTriggerInstance = ReturnType<typeof createUrlTrigger>