export interface QueryParams {
    [key: string]: string | number | null
}

export function parseQueryParams(url: string) {
    const question = url.indexOf("?")
    let hash = url.indexOf("#")
    if (hash == -1 && question == -1) return {}
    if(hash == -1) hash = url.length
    const query = question == -1 || hash == question + 1
        ? url.substring(hash)
        : url.substring(question + 1,hash)
    const result = {} as { [key: string]: string | string[] }
    query.split("&").forEach(function(part) {
        if (!part) return
        part = part.split("+").join(" ")
        const eq = part.indexOf("=")
        let key = eq >- 1 ? part.substr(0,eq) : part
        const val = eq >- 1 ? decodeURIComponent(part.substr(eq+1)) : ""
        const from = key.indexOf("[")
        if(from == -1) {
            result[decodeURIComponent(key)] = val
        } else {
            const to = key.indexOf("]",from)
            const index = decodeURIComponent(key.substring(from + 1, to))
            key = decodeURIComponent(key.substring(0,from))
            if(!result[key]) {
                result[key] = []
            }

            const value = result[key]
            if (!Array.isArray(value)) {
                return
            }
            if(!index) {
                value.push(val)
            } else {
                value[+index] = val
            }
        }
    })
    return result
}

// Simple join url func implementation
export function joinURL(...args: string[]) {
    const url = args
        .map((part, i) => {
            return i === 0
                ? part.trim().replace(/[\/]*$/g, "")
                : part.trim().replace(/(^[\/]*|[\/]*$)/g, "")
        })
        .filter(x => x.length)
        .join("/")

    const lastPart = args[args.length - 1]
    const lastChar = lastPart[lastPart.length - 1]
    return lastChar === "/" && url[url.length - 1] !== "/" ? `${url}/` : url
}

export function buildQuerystring(params: QueryParams) {
    const query = []
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            // eslint-disable-next-line max-len
            const parameter = `${encodeURIComponent(key)}=${encodeURIComponent(
                String(params[key])
            )}`
            query.push(parameter)
        }
    }
    return query.join("&")
}

export function appendQueryParams(url: string, params: QueryParams) {
    const querystring = buildQuerystring(params)
    const lastUrlChar = url[url.length - 1]
    if (lastUrlChar === "?") {
        return `${url}${querystring}`
    } else if (/\?|\&/.test(url) && lastUrlChar !== "&") {
        return `${url}&${querystring}`
    } else {
        return `${url}?${querystring}`
    }
}
