export const os = (): string | null => {
    const userAgent = window.navigator.userAgent
    const platform = window.navigator.platform
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"]
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"]
    const iosPlatforms = ["iPhone", "iPad", "iPod"]
    let os = null
    if (macosPlatforms.indexOf(platform) !== -1) os = "Mac OS"
    else if (iosPlatforms.indexOf(platform) !== -1) os = "iOS"
    else if (windowsPlatforms.indexOf(platform) !== -1) os = "Windows"
    else if (/Android/.test(userAgent)) os = "Android"
    else if (/Linux/.test(platform)) os = "Linux"

    return os
}
