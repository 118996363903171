import { listenMessages, Payload } from "../lib/PostMessage"
import { getCookie } from "../lib/cookies"
import { applyPageFilter } from "../lib/PageFilter"
import { highlightElement } from "./highlight"

const SEARCH_REQUEST_EVENT_TYPE = "rete-element-search-request"
const SEARCH_RESPONSE_EVENT_TYPE = "rete-element-search-response"
const HIGHLIGHT_REQUEST_EVENT_TYPE = "rete-highlight-elements-request"
const CLEAR_HIGHLIGHT_REQUEST_EVENT_TYPE = "rete-clear-highlight-request"

type EventConfig = {
  id: number
  selector: string
  eventCustomName?: string
  eventName?: string
  pageFilter?: RegExp | string
}

interface SearchRequestPayload extends Payload {
    eventsConfigs: EventConfig[]
}

interface HighlightRequestPayload extends Payload {
    eventsConfigs: EventConfig[]
}

type HighlightedElement = {
  domElement: HTMLElement
  clearHighlight: () => void
}

export const useSearchRequest = () => {
    const searchRequest = listenMessages<SearchRequestPayload>({
        eventType: SEARCH_REQUEST_EVENT_TYPE
    })

    searchRequest.subscribe((event) => {
        const payload = event.data
        const eventsConfigs = payload.eventsConfigs

        const foundIds = eventsConfigs
            .filter(eventsConfig => {
                // try/catch is necessary because the selector can be invalid
                try {
                    return document.querySelector(eventsConfig.selector)
                } catch (err) {
                    return false
                }
            })
            .map(eventsConfig => eventsConfig.id)

        window.parent.postMessage(
            {
                type: SEARCH_RESPONSE_EVENT_TYPE,
                foundIds,
            },
            event.origin
        )
    })
}


const useHighlightRequest = () => {
    let highlightedElements: HighlightedElement[] = []

    const highlightRequest = listenMessages<HighlightRequestPayload>({
        eventType: HIGHLIGHT_REQUEST_EVENT_TYPE,
    })

    const clearHighlightRequest = listenMessages({
        eventType: CLEAR_HIGHLIGHT_REQUEST_EVENT_TYPE,
    })

    const clearAll = () => {
        for (const { clearHighlight } of highlightedElements) {
            clearHighlight()
        }
        highlightedElements = []
    }

    highlightRequest.subscribe(({ data }) => {
        const { eventsConfigs } = data

        clearAll()
        
        for (const eventsConfig of eventsConfigs) {
            if (!applyPageFilter(eventsConfig.pageFilter)) {
                continue
            }

            const elements = document.querySelectorAll(eventsConfig.selector)

            for (const element of elements) {
                if (element instanceof HTMLElement) {
                    const { eventCustomName, eventName, selector } = eventsConfig
                    const text = eventCustomName || eventName || selector
                    const clear = highlightElement(element, text)
                    highlightedElements.push({
                        domElement: element,
                        clearHighlight: clear,
                    })
                }
            }
        }
    })

    clearHighlightRequest.subscribe(() => {
        clearAll()
    })
}

const analyticsToolsApiEnabled = () =>
    getCookie("rete-analytics-tools-api") === "true"

export const useAnalyticsToolsApi = () => {
    if (analyticsToolsApiEnabled()) {
        useSearchRequest()
        useHighlightRequest()
    }
}
