import { TrackerEvent } from "./Event"

const EVENTS_STATS_LC_KEY = "rete-users-events-stats"

export type EventsStats = {
    allEventsCount: number
    events: {
        [key: string]: number
    }
}

type StatsStorage = {
    [key: string]: EventsStats
}

export type CreateStatsManagerParams = {
    userId: string
}

const initStats = (): EventsStats => ({
    allEventsCount: 0,
    events: {},
})

export interface EventsStatsManager {
    increment(event: TrackerEvent): number
    getCurrentUserEventCount(event?: TrackerEvent): number
    getCurrentSessionEventCount(event?: TrackerEvent): number
    getUserStats(): EventsStats
    getSessionStats(): EventsStats
}

const isStatsValid = (stats: any) => {
    const isObject = typeof stats === "object"
    const hasAllEventsCountField = typeof stats.allEventsCount === "number"
    const hasEventsField = typeof stats.events === "object"
    if (!isObject || !hasAllEventsCountField || !hasEventsField) return false
    const isNumericKV = Object.getOwnPropertyNames(stats.events).every(key => {
        const value = stats.events[key]
        return (
            typeof value === "number" &&
            !window.isNaN(value) &&
            window.isFinite(value)
        )
    })

    return isNumericKV
}

const isStorageValid = (storage: any, notDeep = false) => {
    const isObject =
        typeof storage === "object" &&
        !Array.isArray(storage) &&
        Boolean(storage)
    if (!isObject) return false
    if (notDeep) return true
    return Object.getOwnPropertyNames(storage).every(userId => {
        return isStatsValid(storage[userId])
    })
}

const readUserStats = (userId: string): EventsStats => {
    const emptyStats = initStats()
    try {
        const serialized = window.localStorage.getItem(EVENTS_STATS_LC_KEY)
        if (serialized === null) return emptyStats
        const parsedStorage = JSON.parse(serialized)
        if (!isStorageValid(parsedStorage)) return emptyStats
        const statsStorage = parsedStorage as StatsStorage
        return statsStorage[userId] || emptyStats
    } catch {
        return emptyStats
    }
}

const writeUserStats = (userId: string, stats: EventsStats) => {
    const writeIgnorePrevStats = () => {
        window.localStorage.setItem(
            EVENTS_STATS_LC_KEY,
            JSON.stringify({
                [userId]: stats,
            })
        )
    }

    try {
        const serialized = window.localStorage.getItem(EVENTS_STATS_LC_KEY)
        if (serialized === null) {
            writeIgnorePrevStats()
            return
        }
        const parsedStorage = JSON.parse(serialized)
        if (!isStorageValid(parsedStorage, true)) {
            writeIgnorePrevStats()
            return
        }
        window.localStorage.setItem(
            EVENTS_STATS_LC_KEY,
            JSON.stringify({
                ...parsedStorage,
                [userId]: stats,
            })
        )
    } catch {
        writeIgnorePrevStats()
    }
}

export const createStatsManager = ({
    userId,
}: CreateStatsManagerParams): EventsStatsManager => {
    const sessionStats = initStats()
    let userStats = readUserStats(userId)

    const updateUserStatsByStorage = () => (userStats = readUserStats(userId))

    const increment = (event: TrackerEvent) => {
        updateUserStatsByStorage()
        userStats.events[event.event_name] =
            typeof userStats.events[event.event_name] === "number"
                ? userStats.events[event.event_name] + 1
                : 1
        userStats.allEventsCount += 1
        writeUserStats(userId, userStats)

        sessionStats.events[event.event_name] =
            typeof sessionStats.events[event.event_name] === "number"
                ? sessionStats.events[event.event_name] + 1
                : 1
        sessionStats.allEventsCount += 1
        return userStats.events[event.event_name]
    }

    const getUserStats = () => {
        updateUserStatsByStorage()
        return userStats
    }
    const getSessionStats = () => sessionStats

    const getCurrentUserEventCount = (event?: TrackerEvent) => {
        updateUserStatsByStorage()
        if (event) {
            return typeof userStats.events[event.event_name] === "number"
                ? userStats.events[event.event_name]
                : 0
        }
        return userStats.allEventsCount
    }

    const getCurrentSessionEventCount = (event?: TrackerEvent) => {
        if (event) {
            return typeof sessionStats.events[event.event_name] === "number"
                ? sessionStats.events[event.event_name]
                : 0
        }
        return sessionStats.allEventsCount
    }

    return {
        increment,
        getCurrentUserEventCount,
        getCurrentSessionEventCount,
        getSessionStats,
        getUserStats,
    }
}
