import { browser, os } from "./detect"
import { md5 } from "./utils"
import { Fraction } from "./Fraction"

// TODO: duplicate types (Config.ts) !!
type SendValue = "none" | "hashOfValue" | "plainValue"

/**
 * This object configures endpoint-specific send options
 */
type EndpointOptions = {
    /**
     * Endpoint name
     */
    name: string
    /**
     * Whether value for input should be submitted as hash, as an actual value
     * or shouldn't be submitted at all. If not specified, then
     * `defaultSendValue` will be used.
     */
    sendValue?: SendValue
}

type BaseEndpointOptions = {
    sendValue?: SendValue
}

/**
 * Detected features of user device
 */
// eslint-disable-next-line max-len
const getPlatform = () =>
    `${browser()}, ${os()}, ${window.innerWidth}x${window.innerHeight}`


/**
 * Payload data collected by {@link EventFactory}
 */
export interface EventPayload {
    event_name: string
    event_custom_name?: string
    event_value?: string
    event_trigger?: string
    extensive_event_trigger?: string
    matched_config_id?: number
    has_matched_config?: 1 | 0
    dom_context?: string
    dom_data_attrs?: string
    collect_dom?: 1 | 0
    params?: string
    event_auto?: boolean
    current_location: string
    current_type?: string
    next_location?: string
    next_type?: string
    version: string
    source: string
    page_name?: string
    user_id: string
    external_referrer?: string
    fingerprint?: string
    dom_info?: string
    api_info?: string
    index?: number
    dmp_id?: string
    dmp_response_ms?: number
    dmp_user_info?: string
    client_session_id?: string
    client_session_event_index?: number
    source_selector?: string
    cookies?: string
    is_iframe?: 1 | 0
    parent_window_session_id?: string
    parent_window_user_id?: string
    parent_window_not_responding?: 1 | 0
    base_endpoint_enabled_by_rete_fraction?: 1 | 0
    base_endpoint_enabled_by_ga_fraction?: 1 | 0
    base_endpoint_enabled_by_ym_fraction?: 1 | 0
    base_endpoint_enabled_by_trigger?: 1 | 0
    base_endpoint_enabled_by_force?: 1 | 0
    base_endpoint_enabled_by_session_fraction?: 1 | 0
    base_endpoint_enabled_by_url_parameter?: 1 | 0
    base_endpoint_enabled_by_specific_event_fraction?: 1 | 0
    base_endpoint_options: BaseEndpointOptions
    additional_endpoints_options?: EndpointOptions[]
    fraction?: Fraction
}

export type EndpointView = Omit<
    TrackerEvent,
    | "endpoints_options"
    | "base_endpoint_options"
    | "toString"
    | "setIndex"
    | "setSessionIndex"
    | "endpointView"
>

/**
 * Event with complete data to send to the API. Contains sanitized
 * {@link EventPayload} and addition fields.
 */
export class TrackerEvent {
    event_name!: string
    event_custom_name?: string
    event_value?: string
    event_trigger?: string
    extensive_event_trigger?: string
    matched_config_id?: number
    has_matched_config?: boolean
    dom_context?: string
    dom_data_attrs?: string
    collect_dom?: 1 | 0
    params?: string
    event_auto!: 1 | 0
    event_timestamp!: number
    event_timestamp_ms!: number
    current_location!: string
    current_type!: string
    next_location?: string
    next_type?: string
    version!: string
    source!: string
    page_name?: string
    user_id!: string
    external_referrer?: string
    fingerprint?: string
    dom_info?: string
    api_info?: string
    index?: number
    dmp_id?: string
    dmp_response_ms?: number
    dmp_user_info?: string
    client_session_id?: string
    client_session_event_index?: number
    source_selector?: string
    cookies?: string
    is_iframe?: 1 | 0
    parent_window_session_id?: string
    parent_window_user_id?: string
    parent_window_not_responding?: 1 | 0
    base_endpoint_enabled_by_rete_fraction?: 1 | 0
    base_endpoint_enabled_by_ga_fraction?: 1 | 0
    base_endpoint_enabled_by_ym_fraction?: 1 | 0
    base_endpoint_enabled_by_trigger?: 1 | 0
    base_endpoint_enabled_by_force?: 1 | 0
    base_endpoint_enabled_by_url_parameter?: 1 | 0
    base_endpoint_enabled_by_session_fraction?: 1 | 0
    base_endpoint_enabled_by_specific_event_fraction?: 1 | 0
    base_endpoint_options!: BaseEndpointOptions
    additional_endpoints_options?: EndpointOptions[]
    noHash!: boolean
    fraction?: Fraction

    constructor(payload: EventPayload, noHash = false) {
        const {
            event_name,
            event_custom_name,
            event_value,
            event_trigger,
            extensive_event_trigger,
            dom_context,
            dom_data_attrs,
            collect_dom,
            params,
            event_auto,
            current_location,
            current_type,
            next_location,
            next_type,
            version,
            source,
            page_name,
            user_id,
            external_referrer,
            fingerprint,
            dom_info,
            api_info,
            index,
            dmp_id,
            cookies,
            is_iframe,
            parent_window_session_id,
            parent_window_user_id,
            parent_window_not_responding,
            source_selector,
            dmp_response_ms,
            dmp_user_info,
            client_session_id,
            client_session_event_index,
            base_endpoint_enabled_by_rete_fraction,
            base_endpoint_enabled_by_ga_fraction,
            base_endpoint_enabled_by_ym_fraction,
            base_endpoint_enabled_by_trigger,
            base_endpoint_enabled_by_force,
            base_endpoint_enabled_by_url_parameter,
            base_endpoint_enabled_by_session_fraction,
            base_endpoint_enabled_by_specific_event_fraction,
            base_endpoint_options,
            has_matched_config,
            matched_config_id,
            additional_endpoints_options,
            fraction,
        } = payload
        Object.assign(this, {
            event_timestamp: (Date.now() / 1000) | 0,
            event_timestamp_ms: Date.now(),
            event_name,
            event_value,
            event_trigger,
            extensive_event_trigger,
            dom_context,
            dom_data_attrs,
            collect_dom,
            event_auto: event_auto ? 1 : 0,
            current_location,
            event_custom_name,
            current_type,
            next_location,
            next_type,
            version,
            source,
            page_name,
            cookies,
            params,
            user_id,
            external_referrer,
            fingerprint,
            dom_info,
            api_info,
            index,
            dmp_id,
            dmp_response_ms,
            dmp_user_info,
            client_session_id,
            client_session_event_index,
            source_selector,
            is_iframe,
            parent_window_session_id,
            parent_window_user_id,
            parent_window_not_responding,
            platform: getPlatform(),
            base_endpoint_enabled_by_rete_fraction:
                typeof base_endpoint_enabled_by_rete_fraction === "number"
                    ? base_endpoint_enabled_by_rete_fraction
                    : 0,
            base_endpoint_enabled_by_ga_fraction:
                typeof base_endpoint_enabled_by_ga_fraction === "number"
                    ? base_endpoint_enabled_by_ga_fraction
                    : 0,
            base_endpoint_enabled_by_ym_fraction:
                typeof base_endpoint_enabled_by_ym_fraction === "number"
                    ? base_endpoint_enabled_by_ym_fraction
                    : 0,
            base_endpoint_enabled_by_trigger:
                typeof base_endpoint_enabled_by_trigger === "number"
                    ? base_endpoint_enabled_by_trigger
                    : 0,
            base_endpoint_enabled_by_force:
                typeof base_endpoint_enabled_by_force === "number"
                    ? base_endpoint_enabled_by_force
                    : 0,
            base_endpoint_enabled_by_url_parameter:
                typeof base_endpoint_enabled_by_url_parameter === "number"
                    ? base_endpoint_enabled_by_url_parameter
                    : 0,
            base_endpoint_enabled_by_session_fraction:
                typeof base_endpoint_enabled_by_session_fraction === "number"
                    ? base_endpoint_enabled_by_session_fraction
                    : 0,
            base_endpoint_enabled_by_specific_event_fraction:
                typeof base_endpoint_enabled_by_specific_event_fraction === "number"
                    ? base_endpoint_enabled_by_specific_event_fraction
                    : 0,
            base_endpoint_options,
            additional_endpoints_options,
            noHash,
            has_matched_config,
            matched_config_id,
            fraction,
        })
    }

    setIndex(index: number) {
        this.index = index
    }

    setSessionIndex(index: number) {
        this.client_session_event_index = index
    }

    endpointView(endpointName?: string): EndpointView {
        const obj = {} as any
        const excludeKeys = [
            "additional_endpoints_options",
            "base_endpoint_options",
            "noHash",
        ]

        for (const key in this) {
            if (this.hasOwnProperty(key) && !excludeKeys.includes(key)) {
                obj[key] = this[key]
            }
        }

        const modifyEventValue = (sendValue?: SendValue) => {
            if (this.noHash) {
                return
            }
            if (sendValue === "none") {
                obj.event_value = ""
            } else if (sendValue === "hashOfValue") {
                obj.event_value = md5(obj.event_value || "")
            }
        }

        if (endpointName) {
            const endpointsOptions = this.additional_endpoints_options || []
            const endpointOptions = endpointsOptions.find(
                ({ name }) => name === endpointName
            )

            if (endpointOptions && endpointOptions.sendValue) {
                modifyEventValue(endpointOptions.sendValue)
            } else {
                modifyEventValue(this.base_endpoint_options.sendValue)
            }
        } else {
            modifyEventValue(this.base_endpoint_options.sendValue)
        }

        return obj
    }

    toString(): string {
        const querystring = []
        const endpointView = this.endpointView() as any
        for (const key in endpointView) {
            if (endpointView.hasOwnProperty(key) && endpointView[key]) {
                querystring.push(
                    `${key}=${encodeURIComponent(
                        (endpointView[key] as unknown) as string | number
                    )}`
                )
            }
        }
        return querystring.join("&")
    }
}
